import { Component, useState, useCallback, useMemo, differenceBy } from 'react';
import { updateData, deleteItem, customTableStyles } from '../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import DataTable from 'react-data-table-component';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import * as XLSX from 'xlsx/xlsx.mjs';
import moment from 'moment';
import { toast } from 'react-toastify';


class AssetMapping extends Component {
  constructor(props){
    super(props);
    this.state = {
      data : [],       
      projects: [],
      pageIndex: 1,
      isLoading : true,
      show : false,
      show_content : ""
    };    
    this.edit = this.edit.bind(this);
    this.view = this.view.bind(this);    
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  async getProjects() {
    try {
      const response = await fetch('api/projects');
      const json = await response.json();
      this.setState({ projects:json });
    } catch (error) {
      console.error(error);
    }
  }  

  async getAssets(){
    try {
      const response = await fetch('/api/assets');
      const json = await response.json();
      this.setState({ data: json.length?json:[] });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }  

  handlePageChange(newPageIndex) {
    this.setState({
      pageIndex: newPageIndex
    });
  }

  view(Id) {    
    const orders = this.state.data;
    const selectedAsset = orders.filter(e=> e.id === Id)[0];
    this.setState(selectedAsset);
    this.setState({ show: true, show_content: "view", filename: "" });
  }

  edit(Id) {    
    const orders = this.state.data;
    const selectedAsset = orders.filter(e=> e.id === Id)[0];
    this.setState(selectedAsset);
    this.setState({ show: true, show_content: "form", filename: "" });
  }

  delete(Ids) {        
    deleteItem(`/api/assets?docRef=[${Ids}]`)
    .then((response) => {
      toast.info(response.message);      
      this.getAssets();
    });
  }
 
  componentDidMount() {
    this.getProjects();
    this.getAssets();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = this.state;
    this.setState({ show: false, isLoading: true });
    delete data.data;
    delete data.isLoading;
    if(data.id){
      updateData('/api/asset/'+data.id, data)
        .then((response) => {
          toast.success(response.message);          
          this.getAssets();
      });
    }else{
      toast.error("Something went wrong! Please try again");
    }
  }

  render() {
    if(!this.state.isLoading) {
      const data = this.state.data;     


      return(
        <>
          {!this.state.show?(
            <>                   
              <AssetList data={data} projects={this.state.projects} view={this.view} edit={this.edit} delete={this.delete} pageIndex={this.state.pageIndex} handlePageChange={this.handlePageChange} />
            </>
          ):(
            <>
              {this.state.show_content==="form"?(
                <>
                  <div className="asset-form p-3 bg-white shadow-sm rounded border-0 w-50">
                    <h4 className="mb-3 fw-bold"> Order #{this.state.primary_no} </h4>
                    <form onSubmit={this.handleSubmit}>
                      <fieldset id="asset-from" className="my-0 mx-auto">
                        <div className="row">
                          <div className="col-xs-12 col-md-12 mb-3">
                            <label htmlFor="motor_sr_no">Motor Serial No.</label>
                            <input type="text" name="motor_sr_no" className="form-control shadow-none" maxLength="100" value={this.state.motor_sr_no} onChange={this.handleInputChange} />
                          </div>                          
                          <div className="col-xs-12 col-md-12 mb-3">
                            <label htmlFor="controller_sr_no">Controller Serial No.</label>
                            <input type="text" name="controller_sr_no" className="form-control shadow-none" maxLength="100" value={this.state.controller_sr_no} onChange={this.handleInputChange} />
                          </div>
                          <div className="col-xs-12 col-md-12 mb-3">
                            <label htmlFor="pump_head_sr_no">Pump Head Serial No.</label>
                            <input type="text" name="pump_head_sr_no" className="form-control shadow-none" maxLength="100" value={this.state.pump_head_sr_no} onChange={this.handleInputChange} />
                          </div>
                          <div className="col-xs-12 col-md-12 mb-3">
                            <label htmlFor="pv_sr_no">PV Serial No.</label>
                            <textarea name="pv_sr_no" className="form-control shadow-none" rows={4} maxLength="255" value={this.state.pv_sr_no} onChange={this.handleInputChange} />
                          </div>
                        </div>                                        
                        <div className="pt-2">
                          <input type="submit" className="btn btn-primary fw-bold" value="Submit" />
                          <button type="button" className="btn btn-outline-secondary fw-bold ms-3" onClick={()=> this.setState({show: false})}>Cancel</button>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </>
              ):(
                <ViewAsset data={this.state} close={()=> this.setState({show: false})} />
              )}
            </>
          )}
        </>
      );
    }
    else {
      return(
        <>Loading...</>
      );
    }
  }
}

const AssetList = (props)=> {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [data, setData] = useState(props.data);
  const [filters, setFilters] = useState({
    project_code: "",
    searchText: ""
  });
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);       

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    
    // Take the shalow copy of filters and update it with the new key value pair and use it for filtering..
    const currentFilters = {...filters, [name]: value};

    // Update the filter state
    setFilters((prevState) => ({
      ...prevState,
      [name]: value
    }));
  
    // Filter the data based on all filters
    let filteredItems = props.data.filter((item) => {
      // Check if any of the filters have a non-empty value
      const hasNonEmptyFilter = Object.values(currentFilters).some((filterValue) => filterValue !== "");
  
      // If no filter has a non-empty value, return true for all items
      if (!hasNonEmptyFilter) return true;
  
      // Apply filters sequentially
      return Object.entries(currentFilters).every(([filterName, filterValue]) => {
        // If the filter value is empty, skip filtering for this filter
        if (filterValue === "") return true;
  
        // Perform filtering based on the filter name
        if (filterName === "searchText") {
          const primaryNoMatches = item.primary_no && item.primary_no.toLowerCase().includes(currentFilters[filterName].toLowerCase());
          const motorMatches = item.motor_sr_no && item.motor_sr_no.toLowerCase().includes(currentFilters[filterName].toLowerCase());
          const controllerMatches = item.controller_sr_no && item.controller_sr_no.toLowerCase().includes(currentFilters[filterName].toLowerCase());
          const pumpMatches = item.pump_head_sr_no && item.pump_head_sr_no.toLowerCase().includes(currentFilters[filterName].toLowerCase());
          const pvMatches = item.pv_sr_no && item.pv_sr_no.toLowerCase().includes(currentFilters[filterName].toLowerCase());

          return primaryNoMatches || motorMatches || controllerMatches || pumpMatches || pvMatches;
        } else {
          return item[filterName] == filterValue;
        }
      });
    });
  
    // Update the filtered data state
    setData(filteredItems);
  };
  
  const handleClear = () => {
    console.log(filters);      
    setResetPaginationToggle(!resetPaginationToggle);
    setFilters({
      project_code: "",      
      searchText: ""
    });
    setData(props.data);     
  };  

  const actionComponentMemo = useMemo(() => {    
    const XLSXDownload = () => {            
      var worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      const filename = `OMS_Assets_${moment().format('YYYYMMDD')}`;
      XLSX.utils.book_append_sheet(workbook, worksheet, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    }

    return (
      <div className="d-flex justify-content-end align-items-center">
        <div className="input-group input-group-sm mx-2">
          <select name="project_code" className="form-select shadow-none" value={filters.project_code} onChange={handleFilterChange} style={{maxWidth: '220px'}} >
            <option value="">-- Project filter --</option> 
            {
              props.projects.map(p=>
                <option key={"P"+p.id} value={p.project_code}>{p.project_name}</option> 
              )
            }
          </select>            
          <input
          name="searchText"
           type="text" 
           className="form-control shadow-none" 
           placeholder="Search by asset, order no" 
           aria-label="Search area"          
           value={filters.searchText} 
           onChange={handleFilterChange}   
          />        
          <button className="btn btn-sm btn-light border" type="button" onClick={handleClear}>
            <FontAwesomeIcon icon={faClose} />
          </button>          
        </div>
        <div>
          <button className="btn btn-sm btn-outline-success mx-2 d-flex align-items-center" type="button" onClick={XLSXDownload}>
            <FontAwesomeIcon className="pe-2" icon={faFileExcel} /> Export
          </button>
        </div>
      </div>
    );
  }, [filters, resetPaginationToggle]);

  const THeader = ()=> {
    return(
      <>
        <h4 className="mb-0">Assets</h4>       
      </>
    );
  }

  const columns = [      
      {
          name: 'Primary No.',
          selector: row => row.primary_no,
          sortable: true,
      },
      {
          name: 'Motor SR No.',
          selector: row => row.motor_sr_no,
          sortable: true,
      },
      {
          name: 'Controller SR No.',
          selector: row => row.controller_sr_no,
          sortable: true,
      },
      {
          name: 'Pump Head SR No.',
          selector: row => row.pump_head_sr_no,
          sortable: true,
      },
      {
          name: 'PV SR No.',
          selector: row => row.pv_sr_no,
          sortable: true,
      },          
      {
          name: 'Action',
          selector: row => row.edit,
      }
  ];

  const tableData = data.map((item, index)=>{
    return {
      id               : item.id,
      primary_no       : item.primary_no,
      motor_sr_no      : item.motor_sr_no,
      controller_sr_no : item.controller_sr_no,
      pump_head_sr_no  : item.pump_head_sr_no,
      pv_sr_no         : item.pv_sr_no?item.pv_sr_no.split(";").map(pv=> <p className='mb-0'>{pv}</p>):'',
      edit: <FontAwesomeIcon icon={faPenToSquare} color="blue" className="action-icons" onClick={()=> props.edit(item.id)} />
    }
  });

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      confirmAlert({
        title: 'Delete confirmation',
        message: `Are you sure you want to clear all assets in:\r ${selectedRows.map(r => r.primary_no)}?`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              var Ids = selectedRows.map(r => r.id);              
              props.delete(Ids);
              setToggleCleared(!toggleCleared);
            }
          },
          {
            label: 'No',
            onClick: () => console.log("Delete operation cancelled.")
          }
        ]
      });        
        // setData(differenceBy(data, selectedRows, 'title'));      
      
    };

    return (
      <button key="delete" className="btn btn-sm btn-danger" onClick={handleDelete}>
        <FontAwesomeIcon className="me-2" icon={faTrashCan} />
         Clear All
      </button>
    );
  }, [data, selectedRows, toggleCleared]);

  
  return(
    <>      
      <div className="mb-2 block p-2">
        <div className="shadow">
          <DataTable
            title={<THeader />}                        
            columns={columns}
            data={tableData}
            actions={actionComponentMemo}
            customStyles={customTableStyles}
            pagination
            paginationDefaultPage={props.pageIndex}
            onChangePage={props.handlePageChange}
            fixedHeader
            fixedHeaderScrollHeight="450px" //remove this if you dont need table scroll
            selectableRows
            selectableRowsHighlight
            selectableRowsRadio="checkbox"
             //remove this if you dont need row hover
            // pointerOnHover //remove this if you dont need pointer while row hover
            className=" border z-10"
            contextActions={contextActions}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared}                 
          />
        </div>
      </div>
    </>
  );
}

const ViewAsset = (props)=> {
  return (
    <div className="p-3 bg-light shadow-sm rounded">
      <h4 className="mb-3 fw-bold"> {props.data.title} </h4>
      <div className="row">
        <div className="col-md-6 mb-3">
          <p className="mb-1">Asset Name</p>
          <h6>{props.data.asset_name}</h6>
        </div>
        <div className="col-md-6 mb-3">
          <p className="mb-1">Vendor</p>
          <h6>{props.data.vendor_name}</h6>
        </div>               
        <div>
          <button className="btn btn-secondary fw-bold" onClick={props.close}>Close</button>
        </div>
      </div>
    </div>
  );
}

export { AssetMapping };
