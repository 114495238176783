import { Component, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { postFile, deleteItem, customTableStyles } from '../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faClose, faFileLines } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan, faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import DataTable from 'react-data-table-component';
import ImportFile from '../downloads/consumers_import.xlsx';
import { toast } from 'react-toastify';

const XLSX = require("xlsx");
const moment = require('moment');


class ConsumerList extends Component {
  constructor(props){
    super(props);
    this.state = {
      data : [],      
      projects: [],
      selectedOrder : {},      
      isLoading : true,
      show : false,
      show_content : "",
      project_filter : "",
      status_filter: ""
    };
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.delete = this.delete.bind(this);    
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleStateUpdate = this.handleStateUpdate.bind(this);
  }

  async getOrders(){
    try {
      const response = await fetch('api/orders');
      const json = await response.json();
      this.setState({ data:json.length?json:[] });
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async getProjects(){
    try {
      const response = await fetch('api/projects');
      const json = await response.json();
      this.setState({ projects:json });
    } catch (error) {
      console.error(error);
    }
  }  

  add() {
    this.setState({
      id: "",
      project_id: "",
      show: true,
      show_content: "form"
    });
  } 

  edit(e) {
    const id = parseInt(e.target.id);
    const orders = this.state.data;
    const selectedOrder = orders.filter(e=> e.order_id === id)[0];
    this.setState(selectedOrder);
    this.setState({ show: true, show_content: "form", filename: "" });
  }

  delete(Ids) {        
    deleteItem(`/api/orders?docRef=[${Ids}]`)
    .then((response) => {      
      toast.info(response.message);
      this.getOrders();
    }).catch(e=> toast.error(e));
  }

  componentDidMount() {
    this.getProjects();
    this.getOrders();
  }

  handleStateUpdate = (updatedObj) => {
    var currentDate = new Date();
    this.setState((prevState) => ({
      selectedOrder: {
        ...prevState.selectedOrder,
        [updatedObj.key]: updatedObj.value,
        [updatedObj.fields.completed_date]: currentDate,
        [updatedObj.fields.completed_by]: sessionStorage.getItem("userName")
      },
      data: prevState.data.map((item) =>
        item.id === updatedObj.order_id
          ? { 
              ...item, 
              [updatedObj.key]: updatedObj.value,
              [updatedObj.fields.completed_date]: currentDate,
              [updatedObj.fields.completed_by]: sessionStorage.getItem("userName")
            }
          : item
      ),
    }));
  };  

  handleInputChange(event){
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value
    });
  }  

  handleSubmit(event) {
    event.preventDefault();
    var ORfileds = document.getElementById("order-fields");
    ORfileds.disabled=true;
    var formData = new FormData();
    var file = document.getElementById("order-sheet").files[0];
    if(file){
      formData.append('File', file);
      formData.append("project_id", this.state.project_id);
    }    

    postFile('/api/order', formData)
      .then((response) => {
        toast.success(response.message);
        ORfileds.disabled=false;        
        document.getElementById("order-form").reset();
        this.setState({show: false, show_content:""});        
        this.getOrders();
    }).catch(e=>{
        console.error(e);
        toast.error("Listitem matches with existing SARAL No!. Cannot add items to the list");
        this.setState({show: false, show_content:""});
    });
  }  

  render() {
    if(!this.state.isLoading) {          
      return(
        <>
          {!this.state.show?(
            <>
              <div className="d-flex">              
                <button className="btn btn-sm btn-primary fw-bold mb-1 ms-2" onClick={this.add}>
                  <FontAwesomeIcon icon={faPlus} /> Upload Consumer List
                </button>
                <a href={ImportFile} className="fw-bold mt-1 ms-2">
                  <FontAwesomeIcon icon={faFileExcel} /> Download Template
                </a>
              </div>
              <OrderList data={this.state.data} projects={this.state.projects} edit={this.edit} delete={this.delete} /> 
            </>
          ):(
            <>
              {this.state.show_content==="form"?(
                <>
                  <div className="card order-form p-3 bg-white shadow-sm rounded w-50 border-0">
                    <h4 className="mb-3 fw-bold"> New Consumer List </h4>
                    <form id="order-form" onSubmit={this.handleSubmit}>
                      <fieldset id="order-fields" className="my-0 mx-auto"> 
                        <div className="mb-3">                          
                          <label htmlFor="title">Project</label>
                          <select name="project_id" className="form-select" value={this.state.project_id} onChange={this.handleInputChange}> 
                            <option value=""> -- Select Project --</option>
                            {
                              this.state.projects.map(p=>
                                <option key={"PJ"+p.id} value={p.id}> {p.project_code} - {p.project_name} </option>
                              )
                            }
                          </select>
                        </div>
                        {
                          this.state.project_id &&
                          <> 
                            <div className="mb-3">
                              <p className="text-secondary fw-light">Upload your excel sheet here to generate the consumer list.</p>  
                              <label htmlFor="title">Consumer List File</label>
                              <input type="file" id="order-sheet" className="form-control" onChange={this.handleFileChange} required /> 
                            </div>
                            <div className="my-2">
                              <input type="submit" className="btn btn-primary fw-bold" value="Submit" />
                              <button type="button" className="btn btn-outline-secondary fw-bold ms-3" onClick={()=> this.setState({show: false})}>Cancel</button>
                            </div>
                          </>
                        }                        
                      </fieldset>
                    </form>
                  </div>
                </>
              ):(
                <></>
              )}
            </>
          )}
        </>
      );
    }
    else {
      return(
        <>Loading...</>
      );
    }
  }
}

const OrderList = (props)=> {
  let navigate = useNavigate();
  let location = useLocation();
  const [orderStatuses, setOrderStatuses] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    project_code: "",
    application_status: "",
    searchText: ""
  });
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false); 
  const [currentPageIndex, setCurrentPageIndex]  = useState(1);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    
    // Take the shalow copy of filters and update it with the new key value pair and use it for filtering..
    const currentFilters = {...filters, [name]: value};

    // Update the filter state
    setFilters((prevState) => ({
      ...prevState,
      [name]: value
    }));
  
    // Filter the data based on all filters
    let filteredItems = props.data.filter((item) => {
      // Check if any of the filters have a non-empty value
      const hasNonEmptyFilter = Object.values(currentFilters).some((filterValue) => filterValue !== "");
  
      // If no filter has a non-empty value, return true for all items
      if (!hasNonEmptyFilter) return true;
  
      // Apply filters sequentially
      return Object.entries(currentFilters).every(([filterName, filterValue]) => {
        // If the filter value is empty, skip filtering for this filter
        if (filterValue === "") return true;
  
        // Perform filtering based on the filter name
        if (filterName === "searchText") {
          const farmerMatches = item.farmer_name && item.farmer_name.toLowerCase().includes(filterValue.toLowerCase());
          const primaryNoMatches = item.primary_no && item.primary_no.toLowerCase().includes(filterValue.toLowerCase());
          return farmerMatches || primaryNoMatches;
        } else {
          return item[filterName] == filterValue;
        }
      });
    });
  
    // Update the filtered data state
    setData(filteredItems);
  };
  
  const handleClear = () => {
    console.log(filters);      
    setResetPaginationToggle(!resetPaginationToggle);
    setFilters({
      project_code: "",
      application_status: "",
      searchText: ""
    });
    setData(props.data);     
  };

  const actionComponentMemo = useMemo(() => {    

    const XLSXDownload = () => {
      var worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      const filename = `OMS_Consumer_Report_${moment().format('YYYYMMDD')}`;
      XLSX.utils.book_append_sheet(workbook, worksheet, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    }
    
    return (
      <div className="d-flex align-items-center">
        <div className="input-group input-group-sm me-2">
          <select name="project_code" className="form-select shadow-none" value={filters.project_code} onChange={handleFilterChange} style={{maxWidth: '220px'}} >
            <option value="">-- Project filter --</option> 
            {
              props.projects.map(p=>
                <option key={"P"+p.id} value={p.project_code}>{p.project_name}</option> 
              )
            }
          </select>          
          <select name="application_status" className="form-select shadow-none" value={filters.application_status} onChange={handleFilterChange} style={{maxWidth: '220px'}}>
            <option value="">-- Status filter --</option>
            {
              orderStatuses.map((o,i)=>
                <option key={o.status+i} value={o.activity}>{o.activity}</option> 
              )
            }
          </select>
          <input
            name="searchText"
            type="text" 
            className="form-control shadow-none" 
            placeholder="Search by farmer name, saral no ..." 
            aria-label="Search area"          
            value={filters.searchText} 
            onChange={handleFilterChange}          
          />        
          <button className="btn btn-light border" type="button" onClick={handleClear}>
            <FontAwesomeIcon icon={faClose} />
          </button>          
        </div>
        <div>
          <button className="btn btn-sm btn-outline-success mx-2 d-flex align-items-center" type="button" onClick={XLSXDownload}>
            <FontAwesomeIcon className="pe-2" icon={faFileLines} /> Report
          </button>
        </div>
      </div>
    );
  }, [filters, resetPaginationToggle, orderStatuses,]);

  const columns = [
      {
          name: 'Order Primary No.',
          selector: row => row.primary_no,
          sortable: true,         
      },
      {
          name: 'Order Date',
          selector: row => row.order_date,
          sortable: true,          
      },
      {
          name: 'Project Code',
          selector: row => row.project_code,
          sortable: true,          
      },
      {
          name: 'Farmer Name',
          selector: row => row.farmer_name,
          sortable: true,
      },
      {
          name: 'Status',
          selector: row => row.application_status,
          sortable: true,
      },
      {
          name: 'Motor Type',
          selector: row => row.pump_type,
          sortable: true,
      },
      {
          name: 'Pump Sub Type',
          selector: row => row.pump_sub_type,
          sortable: true,
      },
      {
          name: 'Pump Capacity',
          selector: row => row.pump_capacity,
      }
  ]; 

  const tableData = data.map((item, index)=>{
    return {
      id            : item.id,
      primary_no    : item.primary_no,
      order_date    : moment(item.order_date).format("DD/MM/YYYY HH:MM"),
      project_code  : item.project_code,
      farmer_name   : item.farmer_name,
      application_status : item.application_status,
      pump_type: item.pump_type,
      pump_sub_type : item.pump_sub_type,
      pump_capacity : item.pump_capacity,
    }
  });

  const THeader = ()=> {
    return(
      <>
        <h4 className="mb-0">Consumer List</h4>       
      </>
    );
  }

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      confirmAlert({
        title: 'Delete confirmation',
        message: `Are you sure you want to delete:\r ${selectedRows.map(r => r.primary_no)}?`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              var Ids = selectedRows.map(r => r.id);              
              props.delete(Ids);
              setToggleCleared(!toggleCleared);
            }
          },
          {
            label: 'No',
            onClick: () => console.log("Delete operation cancelled.")
          }
        ]
      });        
        // setData(differenceBy(data, selectedRows, 'title'));      
      
    };

    return (
      <button key="delete" className="btn btn-sm btn-danger" onClick={handleDelete}>
        <FontAwesomeIcon className="me-2" icon={faTrashCan} />
         Delete
      </button>
    );
  }, [data, selectedRows, toggleCleared]);

  const getOrderStatuses = async()=> {
    try{
      const response = await fetch("/api/order-activities");
      const json = await response.json();
      setOrderStatuses(json.length?json:[]);
    }catch(e){
      toast.error(e)
    }
  }

  const retainPageState = (pageIndex)=> {
    setCurrentPageIndex(pageIndex);
  }
  
  // Define function to handle page change
  const handlePageChange = newPage => {
    setCurrentPageIndex(newPage);
  };
  
  useEffect(()=>{
    setData(props.data);
    getOrderStatuses();    
    if(location.state && location.state.page){
      retainPageState(location.state.page);
    }
  },[props.data, location])


  return(
    <>      
      <div id="order-list" className="mb-2 block p-2">
        <div className="shadow">
          <DataTable
            title={<THeader />}                        
            columns={columns}
            data={tableData}
            actions={actionComponentMemo}
            customStyles={customTableStyles}
            pagination
            paginationDefaultPage={currentPageIndex}
            onChangePage={handlePageChange}
            fixedHeader
            fixedHeaderScrollHeight="440px" //remove this if you dont need table scroll
            selectableRows
            selectableRowsHighlight
            selectableRowsRadio="checkbox"
            pointerOnHover
            className=" border z-10"
            contextActions={contextActions}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared}  
            onRowClicked={(row)=> navigate(`/consumer-list/${row.id},`,{ state: { page: currentPageIndex }})}
          />
        </div>
      </div>
    </>
  );  
}

export { ConsumerList };
