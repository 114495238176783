import { Component, useState, useCallback, useMemo } from "react";
import {
  postData,
  updateData,
  deleteItem,
  customTableStyles,
} from "../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faClose,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";
import { faTrashCan, faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import DataTable from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import * as XLSX from "xlsx/xlsx.mjs";
import moment from "moment";
import { VendorDetails } from "./vendor_details";
import { toast } from "react-toastify";

class Vendors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pageIndex: 1,
      isLoading: true,
      show: false,
      show_content: "",
    };
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.view = this.view.bind(this);
    this.delete = this.delete.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  async getVendors() {
    try {
      const response = await fetch("/api/vendors");
      const json = await response.json();
      this.setState({ data: json.length ? json : [] });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handlePageChange(newPageIndex) {
    this.setState({
      pageIndex: newPageIndex,
    });
  }

  add() {
    this.setState({
      id: "",
      org_name: "",
      org_code: "",
      address: "",
      phone: "",
      email: "",
      is_active: 1,
      show: true,
      show_content: "form",
    });
  }

  view(row, event) {
    const selectedVendor = row;
    this.setState(selectedVendor);
    this.setState({ show: true, show_content: "view", filename: "" });
  }

  edit(Id) {
    const vendors = this.state.data;
    const selectedVendor = vendors.filter((e) => e.id === Id)[0];
    this.setState(selectedVendor);
    this.setState({ show: true, show_content: "form", filename: "" });
  }

  delete(Ids) {
    deleteItem(`/api/vendors?docRef=[${Ids}]`).then((response) => {
      toast.info(response.message);
      this.getVendors();
    });
  }

  componentDidMount() {
    this.getVendors();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = this.state;
    this.setState({ show: false, isLoading: true });
    delete data.data;
    delete data.isLoading;
    if (data.id) {
      updateData("/api/vendor/" + data.id, data).then((response) => {
        toast.success(response.message);
        this.getVendors();
      });
    } else {
      postData("/api/vendor", data).then((response) => {
        toast.success(response.message);
        this.getVendors();
      });
    }
  }

  render() {
    if (!this.state.isLoading) {
      const data = this.state.data;
      return (
        <>
          {!this.state.show ? (
            <>
              <button
                className="btn btn-sm btn-primary fw-bold mx-2"
                onClick={this.add}
              >
                <FontAwesomeIcon icon={faPlus} /> Add Vendor Company
              </button>
              <VendorList
                data={data}
                view={this.view}
                edit={this.edit}
                delete={this.delete}
                pageIndex={this.state.pageIndex}
                handlePageChange={this.handlePageChange}
              />
            </>
          ) : (
            <>
              {this.state.show_content === "form" ? (
                <div className="vendor-form h-100 w-50">
                  <div className="p-4 bg-white shadow-sm rounded border-0">
                    <h4 className="mb-3 fw-bold">
                      {" "}
                      {this.state.id
                        ? "Edit Vendor Company"
                        : "New Vendor Company"}{" "}
                    </h4>
                    <form onSubmit={this.handleSubmit}>
                      <fieldset id="vendor-from" className="my-0 mx-auto">
                        <div className="row">
                          <div className="col-xs-12 col-md-12 mb-3">
                            <label htmlFor="org_name">
                              Vendor Company Name
                            </label>
                            <input
                              type="text"
                              name="org_name"
                              className="form-control shadow-none"
                              maxLength="100"
                              value={this.state.org_name}
                              onChange={this.handleInputChange}
                              required
                            />
                          </div>
                          <div className="col-xs-12 col-md-12 mb-3">
                            <label htmlFor="org_code">Vendor Code</label>
                            <input
                              type="text"
                              name="org_code"
                              className="form-control shadow-none"
                              maxLength="50"
                              value={this.state.org_code}
                              onChange={this.handleInputChange}
                              required
                            />
                          </div>
                          <div className="col-xs-12 col-md-12 mb-3">
                            <label htmlFor="address">Address</label>
                            <textarea
                              name="address"
                              className="form-control shadow-none"
                              maxLength="255"
                              rows={3}
                              value={this.state.address}
                              onChange={this.handleInputChange}
                            />
                          </div>
                          <div className="col-xs-12 col-md-12 mb-3">
                            <label htmlFor="email">Contact Email</label>
                            <input
                              type="email"
                              name="email"
                              className="form-control shadow-none"
                              maxLength="255"
                              value={this.state.email}
                              onChange={this.handleInputChange}
                            />
                          </div>
                          <div className="col-xs-12 col-md-8 mb-3">
                            <label htmlFor="phone">Contact Phone</label>
                            <input
                              type="text"
                              name="phone"
                              className="form-control shadow-none"
                              maxLength="20"
                              value={this.state.phone}
                              onChange={this.handleInputChange}
                            />
                          </div>
                          <div className="col-xs-12 col-md-4 mb-3">
                            <label htmlFor="title">Status</label>
                            <select
                              name="is_active"
                              className="form-select shadow-none"
                              value={this.state.is_active}
                              onChange={this.handleInputChange}
                              required
                            >
                              <option value="0"> Inactive </option>
                              <option value="1"> Active </option>
                            </select>
                          </div>
                        </div>
                        <div className="pt-2">
                          <input
                            type="submit"
                            className="btn btn-primary fw-bold"
                            value="Submit"
                          />
                          <button
                            type="button"
                            className="btn btn-outline-secondary fw-bold ms-3"
                            onClick={() => this.setState({ show: false })}
                          >
                            Cancel
                          </button>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </div>
              ) : (
                <VendorDetails
                  data={this.state}
                  close={() => this.setState({ show: false })}
                />
              )}
            </>
          )}
        </>
      );
    } else {
      return <>Loading...</>;
    }
  }
}

const VendorList = (props) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = props.data.filter((item) => {
    const nameMatches =
      item.org_name &&
      item.org_name.toLowerCase().includes(filterText.toLowerCase());
    return nameMatches;
  });

  const actionComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    const XLSXDownload = () => {
      var worksheet = XLSX.utils.json_to_sheet(filteredItems);
      const workbook = XLSX.utils.book_new();
      const filename = `OMS_Vendors_${moment().format("YYYYMMDD")}`;
      XLSX.utils.book_append_sheet(workbook, worksheet, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    };

    return (
      <div className="d-flex justify-content-end align-items-center">
        <div className="input-group input-group-sm mx-2">
          <input
            type="text"
            className="form-control shadow-none"
            placeholder="Search by name ..."
            aria-label="Search area"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
          <button
            className="btn btn-light border"
            type="button"
            onClick={handleClear}
          >
            <FontAwesomeIcon icon={faClose} />
          </button>
        </div>
        <div>
          <button
            className="btn btn-sm btn-outline-success mx-2 d-flex align-items-center"
            type="button"
            onClick={XLSXDownload}
          >
            <FontAwesomeIcon className="pe-2" icon={faFileExcel} /> Export
          </button>
        </div>
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "Vendor Code",
      selector: (row) => row.org_code,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.org_name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Action",
      selector: (row) => row.edit,
    },
  ];

  const data = filteredItems.map((item, index) => {
    return {
      id: item.id,
      org_code: item.org_code,
      org_name: item.org_name,
      email: item.email,
      phone: item.phone,
      status: (
        <span
          className={`${
            item.is_active ? "fw-semibold" : "fw-bold text-danger"
          }`}
        >
          {item.is_active ? "Active" : "Inactive"}
        </span>
      ),
      edit: (
        <FontAwesomeIcon
          icon={faPenToSquare}
          color="blue"
          onClick={() => props.edit(item.id)}
        />
      ),
    };
  });

  const THeader = () => {
    return (
      <>
        <h4 className="mb-0">Vendor Companies</h4>
      </>
    );
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      confirmAlert({
        title: "Delete confirmation",
        message: `Are you sure you want to delete:\r ${selectedRows.map(
          (r) => r.org_name
        )}?`,
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              var Ids = selectedRows.map((r) => r.id);
              props.delete(Ids);
              setToggleCleared(!toggleCleared);
            },
          },
          {
            label: "No",
            onClick: () => console.log("Delete operation cancelled."),
          },
        ],
      });
      // setData(differenceBy(data, selectedRows, 'title'));
    };

    return (
      <button
        key="delete"
        className="btn btn-sm btn-danger"
        onClick={handleDelete}
      >
        <FontAwesomeIcon className="me-2" icon={faTrashCan} />
        Delete
      </button>
    );
  }, [data, selectedRows, toggleCleared]);

  return (
    <>
      <div className="mb-2 block p-2">
        <div className="shadow">
          <DataTable
            title={<THeader />}
            columns={columns}
            data={data}
            actions={actionComponentMemo}
            customStyles={customTableStyles}
            pagination
            paginationDefaultPage={props.pageIndex}
            onChangePage={props.handlePageChange}
            fixedHeader
            fixedHeaderScrollHeight="450px" //remove this if you dont need table scroll
            selectableRows
            selectableRowsHighlight
            selectableRowsRadio="checkbox"
            onRowClicked={props.view}
            //remove this if you dont need row hover
            pointerOnHover //remove this if you dont need pointer while row hover
            className=" border z-10"
            contextActions={contextActions}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared}
          />
        </div>
      </div>
    </>
  );
};

export { Vendors };
