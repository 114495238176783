import moment from "moment";

const ProductCategories = [
  {id: "controller", name: "Controller"},
  {id: "motor", name: "Motor"},
  {id: "pump", name: "Pump"},
  {id: "pv", name: "PV"},
  {id: "bos", name: "BOS"},
  {id: "structure", name: "Structure"}
];

export { ProductCategories };