import { createContext } from 'react';
import { defaultThemes }  from 'react-data-table-component';
const AuthContext = createContext();
const ProjectContext = createContext();

async function postFile (url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST',
    body: data
  });
  return response.json();
}

async function postData (url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
  return response.json();
}

async function updateData (url = '', data = {}) {
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
  return response.json();
}

async function deleteItem (url = '') {
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({data:null})
  });
  return response.json();
}

const customTableStyles = {     
  headRow: {
    style: {
      borderBottom: '1px solid rgba(67, 83, 64, 0.47)',
      fontSize:'15px',
      minHeight: '40px',      
      backgroundColor:'#f8f7ff',
    },
  },
  rows: {
    style: {
        borderTopColor: 'black',
        minHeight: '40px',
       // Change background color to white on hover
       '&:hover':{
        backgroundColor:'#fdfcdc',
        color:'black'
      },
    },        
  },
  headCells: {
      style: {
        '&:not(:last-of-type)': {
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          borderRightColor: defaultThemes.default.divider.default,
          justifyContent:'start',
        },
        ':first-of-type': {
          paddingLeft:'10px',
        },       
        justifyContent:'center',
        fontWeight: '700',
        '&:hover':{
          backgroundColor:'#fdfcdc',
          color:'black'
        },
        textTransform:'capitalize',      
      },
  },
  cells: {
      style: {
        '&:not(:last-of-type)': {
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          borderRightColor: defaultThemes.default.divider.default,
          justifyContent:'start',
        },
        ':first-of-type': {
          paddingLeft:'10px',
        }, 
        justifyContent:'center',        
      },
  },
  pagination:{
    style:{
      justifyContent:'center',
      backgroundColor:'#f8f7ff',
      color:'black'
    },
  },
};

export { postData, postFile, updateData, deleteItem, AuthContext, ProjectContext, customTableStyles };
