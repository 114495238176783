import { Component, useCallback, useMemo, useState } from 'react';
import { postFile, updateData, deleteItem, customTableStyles } from '../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faClose } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan, faFileExcel, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import DataTable from 'react-data-table-component';
import ImportFile from '../downloads/pv_serial_import.xlsx';
import { toast } from 'react-toastify';
const XLSX = require("xlsx");
const moment = require('moment');


class SolarPV extends Component {
  constructor(props){
    super(props);
    this.state = {
      data : [],
      selectedSolarPV : {},
      pageIndex: 1,
      isLoading : true,
      show : false,
      show_content : "",
    };
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.view = this.view.bind(this);
    this.delete = this.delete.bind(this);    
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  async getSolarPV(){
    try {
      const response = await fetch('/api/solar-pvs');
      const json = await response.json();
      console.log(json);
      this.setState({ data:json.length?json:[] });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }  

  handlePageChange(newPageIndex) {
    this.setState({
      pageIndex: newPageIndex
    });
  }

  add() {
    this.setState({
      id: "",      
      show: true,
      show_content: "upload"
    });
  } 

  edit(Id) {    
    const products = this.state.data;
    const selectedProduct = products.filter(e=> e.id === Id)[0];
    this.setState(selectedProduct);
    this.setState({ show: true, show_content: "form", filename: "" });
  }

  view(row, event) {
    const selectedSolarPV = this.state.data.filter(e=> e.id === row.id)[0];
    this.setState({show: true, show_content: "", selectedSolarPV: selectedSolarPV});
  }

  delete(Ids) {    
    deleteItem(`/api/solar-pv?docRef=[${Ids}]`)
    .then((response) => {
      toast.info(response.message);
      this.getSolarPV();
    });
  }

  componentDidMount() {
    this.getSolarPV();
  }
  
  handleInputChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });
  }

  handleSubmit(event) {
    event.preventDefault();
    if(this.state.id){
      const data = {
        "lot_ref_no_of_solar_pv": this.state.lot_ref_no_of_solar_pv,
        "solar_pv_serial_no": this.state.solar_pv_serial_no
      };
      updateData(`/api/solar-pv/${this.state.id}`, data)
      .then((response) => {
        toast.success(response.message);        
        this.setState({show: false});        
        this.getSolarPV();
      });
    }else{      
      var formData = new FormData();
      var file = document.getElementById("solar-pv-sheet").files[0];
      if(file){
        var ORfileds = document.getElementById("solar-pv-fields");
        ORfileds.disabled=true;
        formData.append('File', file);
        postFile('/api/solar-pv', formData)
        .then((response) => {
          toast.success(response.message);
          ORfileds.disabled=false;   
          document.getElementById("solar-pv-form").reset();
          this.setState({show: false, show_content:""});
          this.getSolarPV();
        });
      }else{
        alert("Select the file first");
      }         
    }    
  } 

  render() {
    if(!this.state.isLoading) {
      const data = this.state.data;     
      return(
        <>
          {!this.state.show?(
            <>     
              <div className="d-flex">         
                <button className="btn btn-sm btn-primary fw-bold mb-1 ms-2" onClick={this.add}>
                  <FontAwesomeIcon icon={faPlus} /> Upload Solar PV
                </button>
                <a href={ImportFile} className="fw-bold mt-1 ms-2">
                  <FontAwesomeIcon icon={faFileExcel} /> Download Template
                </a>
              </div>              
              <SolarPVList data={data} edit={this.edit} delete={this.delete} view={this.view} pageIndex={this.state.pageIndex} handlePageChange={this.handlePageChange} />
            </>
          ):(
            <>
              {this.state.show_content==="upload"?(
                <>
                  <div className="card solar-pv-form p-3 bg-white shadow-sm rounded w-50 bsolar_pv-0">
                    <h4 className="mb-3 fw-bold"> Upload SolarPV Data </h4>
                    <form id="solar-pv-form" onSubmit={this.handleSubmit}>
                      <fieldset id="solar-pv-fields" className="my-0 mx-auto"> 
                        <div className="mb-3">
                          <p className="text-secondary fw-light">Upload your excel sheet here.</p>  
                          <label htmlFor="title">Solar PV data file</label>
                          <input type="file" id="solar-pv-sheet" className="form-control" onChange={this.handleFileChange} required /> 
                        </div>
                        <div className="my-2">
                          <input type="submit" className="btn btn-primary fw-bold" value="Submit" />
                          <button type="button" className="btn btn-outline-secondary fw-bold ms-3" onClick={()=> this.setState({show: false})}>Cancel</button>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </>
              ):(
                <>
                   <div className="h-100 w-50">
                    <div className="vendor-form p-4 bg-white shadow-sm rounded border-0">
                      <h4 className="mb-3 fw-bold"> Panel details </h4>
                      <form onSubmit={this.handleSubmit}>
                        <fieldset id="solar-pv-update-from" className="my-0 mx-auto">
                          <div className="row">
                            <div className="col-xs-12 col-md-12 mb-3">
                              <label htmlFor="org_name">Lot Reference No.</label>
                              <input type="text" name="lot_ref_no_of_solar_pv" className="form-control shadow-none" maxLength="50" value={this.state.lot_ref_no_of_solar_pv} onChange={this.handleInputChange} required />
                            </div>  
                             <div className="col-xs-12 col-md-12 mb-3">
                              <label htmlFor="org_name">PV Serial No.</label>
                              <input type="text" name="solar_pv_serial_no" className="form-control shadow-none" maxLength="50" value={this.state.solar_pv_serial_no} onChange={this.handleInputChange} required />
                            </div>                                                                                                    
                          </div>                        
                          <div>
                            <input type="submit" className="btn btn-primary fw-bold" value="Submit" />
                            <button type="button" className="btn btn-outline-secondary fw-bold ms-3" onClick={()=> this.setState({show: false})}>Cancel</button>
                          </div>
                        </fieldset>
                      </form>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      );
    }
    else {
      return(
        <>Loading...</>
      );
    }
  }
}

const SolarPVList = (props)=> {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  
  const filteredItems = props.data.filter(item => {
    const lotRefMatches = item.lot_ref_no_of_solar_pv && item.lot_ref_no_of_solar_pv.toLowerCase().includes(filterText.toLowerCase());
    const solar_pvSNoMatches = item.solar_pv_serial_no && item.solar_pv_serial_no.toLowerCase().includes(filterText.toLowerCase());    

    return lotRefMatches || solar_pvSNoMatches
  });

  const actionComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    const XLSXDownload = () => {
      var worksheet = XLSX.utils.json_to_sheet(filteredItems);
      const workbook = XLSX.utils.book_new();
      const filename = `OMS_SolarPV_${moment().format('YYYYMMDD')}`;
      XLSX.utils.book_append_sheet(workbook, worksheet, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    }

    return (
      <div className="d-flex justify-content-end align-items-center">        
        <div className="input-group input-group-sm mx-2">
          <input
           type="text" 
           className="form-control shadow-none" 
           placeholder="Search by project's code, name ..." 
           aria-label="Search area"          
           value={filterText} 
           onChange={e => setFilterText(e.target.value)}          
          />        
          <button className="btn btn-light bsolar_pv" type="button" onClick={handleClear}>
            <FontAwesomeIcon icon={faClose} />
          </button>          
        </div>
        <div>
          <button className="btn btn-sm btn-outline-success mx-2 d-flex align-items-center" type="button" onClick={XLSXDownload}>
            <FontAwesomeIcon className="pe-2" icon={faFileExcel} /> Export
          </button>
        </div>
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
      {
          name: 'Lot Ref No.',
          selector: row => row.lot_ref_no,
          sortable: true,
      },
      {
          name: 'PV Serial No.',
          selector: row => row.solar_pv_serial_no,
          sortable: true,
      },
      {
          name: 'Action',
          selector: row => row.edit
      }      
  ]; 

  const data = filteredItems.map((item, index)=>{
    return {
      id: item.id,
      lot_ref_no: item.lot_ref_no_of_solar_pv,
      solar_pv_serial_no: item.solar_pv_serial_no,      
      edit: <FontAwesomeIcon icon={faPenToSquare} color="blue" className="action-icons" onClick={()=> props.edit(item.id)} />
    }
  });

  const THeader = ()=> {
    return(
      <>
        <h4 className="mb-0">Solar Panels</h4>       
      </>
    );
  }

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      confirmAlert({
        title: 'Delete confirmation',
        message: `Are you sure you want to delete:\r ${selectedRows.map(r => r.lot_ref_no)}?`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              var Ids = selectedRows.map(r => r.id);              
              props.delete(Ids);
              setToggleCleared(!toggleCleared);
            }
          },
          {
            label: 'No',
            onClick: () => console.log("Delete operation cancelled.")
          }
        ]
      });        
        // setData(differenceBy(data, selectedRows, 'title'));      
      
    };

    return (
      <button key="delete" className="btn btn-sm btn-danger" onClick={handleDelete}>
        <FontAwesomeIcon className="me-2" icon={faTrashCan} />
         Delete
      </button>
    );
  }, [data, selectedRows, toggleCleared]);

  
  return(
    <>      
      <div className="mb-2 block p-2">
        <div className="shadow">
          <DataTable
            title={<THeader />}                        
            columns={columns}
            data={data}
            actions={actionComponentMemo}
            customStyles={customTableStyles}
            pagination
            paginationDefaultPage={props.pageIndex}
            onChangePage={props.handlePageChange}
            fixedHeader
            fixedHeaderScrollHeight="450px" //remove this if you dont need table scroll
            selectableRows
            selectableRowsHighlight
            selectableRowsRadio="checkbox"             
            // pointerOnHover
            className=" bsolar_pv z-10"
            contextActions={contextActions}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared}              
          />
        </div>
      </div>
    </>
  );  
}


export { SolarPV };
