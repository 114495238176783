import { Fragment, useEffect, useState  } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { postFile, updateData } from '../utils/constants';
import { Container, Table, Row, Col, Tab, Tabs, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faClose, faFileInvoice, faPencil } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { useParams } from "react-router-dom";
const moment = require('moment');
const OrderActivities = [
    "After Foundation", 
    "Full System", 
    "Controller", 
    "Water Output",
];

function OrderDetails() {    
    const navigate = useNavigate();
    let location = useLocation();
    const { orderId } = useParams();
    const [orderDetails, setOrderDetails] = useState({});
    const [orderItems, setOrderItems] = useState([]);
    const [viewState, setViewState] = useState("");
    const [isLoading, setLoading] = useState(true);
  
    const getOrderDetails = async()=> {
      try{
        const response = await fetch(`/api/order/${orderId}`);
        const json = await response.json();       
        setOrderDetails(json.order.primary_no?json.order:{});
        setOrderItems(json.orderItems.length?json.orderItems:[]);
      }catch(e){
        toast.error(e);
      }finally{
        setLoading(false);
      }
    }
  
    async function generateInvoice() {        
      try {
        const response = await fetch(`/api/invoice-report/${orderId}`);
        const htmlData = await response.text();
        var myWindow = window.open("", "Invoice");
        myWindow.document.write(htmlData);
      } catch (error) {
        console.error(error);
        toast.error(error);
      }   
    }
  
    async function generateJCR() {        
      try {
        const response = await fetch(`/api/jcr-report/${orderId}`);
        const htmlData = await response.text();
        var myWindow = window.open("", "Invoice");
        myWindow.document.write(htmlData);
      } catch (error) {
        console.error(error);
        toast.error(error);
      }   
    }   
  
    useEffect(()=>{
      getOrderDetails();
    }, [])
  
    return (
      !isLoading?(
        <div className="container-fluid bg-white p-3">
          <div className="d-flex justify-content-between mb-3">
            <div>
              <button className="btn border-primary rounded-0 me-2" onClick={()=> generateInvoice()}>
                <FontAwesomeIcon icon={faFileInvoice} color="blue" />
                <span className="text-primary fw-semibold"> Generate Invoice </span>
              </button>
              <button className="btn border-primary rounded-0 mx-2" onClick={()=> generateJCR()}>
                <FontAwesomeIcon icon={faFile} color="blue" />
                <span className="text-primary fw-semibold"> JCR Report </span>
              </button>
            </div>
            <button className="btn btn-outline-secondary rounded-0" onClick={()=> navigate('/consumer-list', { state: { page: location.state?location.state.page:1 } })}>
              <FontAwesomeIcon icon={faClose} /> Close
            </button>
          </div>
          <Tabs
            defaultActiveKey="project"
            id="fill-tab-example"
            className="mb-3"
            fill
            >
            <Tab eventKey="project" title="Project & Order details">
                <Row xs={12}>
                    <Col xs={12} sm={12} lg={12} className="mb-3">
                        {viewState==="order"?(
                            <EditProjectOrder data={orderDetails} getOrderDetails={getOrderDetails} close={()=> setViewState("")} />
                        ):(
                            <>
                                <Table bordered responsive>
                                    <thead>
                                        <tr>
                                            <th colSpan="2">Order & Project Information</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Order Primary No.</td>
                                            <td className="fw-semibold">{orderDetails.primary_no}</td>
                                        </tr>
                                        <tr>
                                            <td>Order Date</td>
                                            <td className="fw-semibold">{orderDetails.order_date?moment(orderDetails.order_date).format("LLL"):""}</td>
                                        </tr>
                                        <tr>
                                            <td>Work Order No.</td>
                                            <td className="fw-semibold">{orderDetails.work_order_no}</td>
                                        </tr>
                                        <tr>
                                            <td>Application No.</td>
                                            <td className="fw-semibold">{orderDetails.application_no}</td>
                                        </tr>
                                        <tr>
                                            <td>Project Code</td>
                                            <td className="fw-semibold">{orderDetails.project_code}</td>
                                        </tr>
                                        <tr>
                                            <td>Project Name</td>
                                            <td className="fw-semibold">{orderDetails.project_name}</td>
                                        </tr>
                                        <tr>
                                            <td>Days to complete order</td>
                                            <td className="fw-semibold">{orderDetails.ottc}</td>
                                        </tr>
                                        <tr>
                                            <td>Days to complete installation</td>
                                            <td className="fw-semibold">{orderDetails.ittc}</td>
                                        </tr>
                                        <tr>
                                            <td>Order Status</td>
                                            <td className="fw-semibold text-success">{orderDetails.application_status}</td>
                                        </tr>
                                        <tr>
                                            <td>Required Pump Type</td>
                                            <td className="fw-semibold">{orderDetails.pump_type}</td>
                                        </tr>
                                        <tr>
                                            <td>Required Pump Subtype</td>
                                            <td className="fw-semibold">{orderDetails.pump_sub_type}</td>
                                        </tr>
                                        <tr>
                                            <td>Required Pump Capacity</td>
                                            <td className="fw-semibold">{orderDetails.pump_capacity?orderDetails.pump_capacity+' HP':''}</td>
                                        </tr>
                                        <tr>
                                            <td>Required Pump Head Length</td>
                                            <td className="fw-semibold">{orderDetails.pump_head_mtrs}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <Button variant="primary" onClick={()=> setViewState("order")}>
                                    <FontAwesomeIcon icon={faPencil} /> Edit
                                </Button>
                            </>
                        )}                        
                    </Col>
                </Row>
            </Tab>
            <Tab eventKey="farmer" title="Farmer Info">
                <Row xs={12}>
                    <Col xs={12} sm={12} lg={12} className="mb-3">
                        {viewState==="farmer"?(
                            <EditFarmer data={orderDetails} getOrderDetails={getOrderDetails} close={()=> setViewState("")} />
                        ):(
                            <>
                                <Table bordered responsive>
                                    <thead>
                                        <tr>
                                            <th colSpan="2">Consumer / Farmer Information</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>SARAL No.</td>
                                            <td className="fw-semibold">{orderDetails.bid_saral_no}</td>
                                        </tr>
                                        <tr>
                                            <td>Farmer Name</td>
                                            <td className="fw-semibold">{orderDetails.farmer_name}</td>
                                        </tr>
                                        <tr>
                                            <td>Village</td>
                                            <td className="fw-semibold">{orderDetails.village}</td>                       
                                        </tr>
                                        <tr>
                                            <td>Taluka</td>
                                            <td className="fw-semibold">{orderDetails.taluka}</td>
                                        </tr>
                                        <tr>
                                            <td>District</td>
                                            <td className="fw-semibold">{orderDetails.district}</td>
                                        </tr>
                                        <tr>
                                            <td>State</td>
                                            <td className="fw-semibold">{orderDetails.state}</td>
                                        </tr>
                                        <tr>
                                            <td>Mobile Number</td>
                                            <td className="fw-semibold">{orderDetails.mobile_number}</td>
                                        </tr>              
                                        <tr>
                                            <td>Latitude</td>
                                            <td className="fw-semibold">{orderDetails.latitude}</td>    
                                        </tr>
                                        <tr>
                                            <td>Longitude</td>
                                            <td className="fw-semibold">{orderDetails.longitude}</td>
                                        </tr>
                                        <tr>
                                            <td>Empanelled Agency</td>
                                            <td className="fw-semibold">{orderDetails.empanelled_agency}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <Button variant="primary" onClick={()=> setViewState("farmer")}>
                                    <FontAwesomeIcon icon={faPencil} /> Edit
                                </Button>
                            </>
                        )}
                    </Col>
                </Row>
            </Tab>
            <Tab eventKey="installer" title="Installer & Service center Info">
                <Row xs={12}>
                    <Col xs={12} sm={12} lg={12} className="mb-3">
                        <Table bordered responsive>
                            <thead>
                            <tr>
                                <th colSpan="2">Installer Information</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Installer Code</td>
                                <td className="fw-semibold">{orderDetails.installer_code}</td>
                            </tr>
                            <tr>
                                <td>Company Name</td>
                                <td className="fw-semibold">{orderDetails.installer_company}</td>
                            </tr>
                            <tr>
                                <td>Address</td>
                                <td className="fw-semibold">{orderDetails.installer_address}</td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td className="fw-semibold">{orderDetails.installer_email}</td>
                            </tr>                
                            <tr>
                                <td>Phone</td>
                                <td className="fw-semibold">{orderDetails.installer_phone}</td>
                            </tr>              
                            </tbody>
                        </Table>
                    </Col>
                    <Col xs={12} sm={12} lg={12} className="mb-3">
                        <Table bordered responsive>
                            <thead>
                            <tr>
                                <th colSpan="2">Service Center Information</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Service Center Code</td>
                                <td className="fw-semibold">{orderDetails.service_center_code}</td>
                            </tr>
                            <tr>
                                <td>Company Name</td>
                                <td className="fw-semibold">{orderDetails.service_center_name}</td>
                            </tr>
                            <tr>
                                <td>Address</td>
                                <td className="fw-semibold">{orderDetails.service_center_address}</td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td className="fw-semibold">{orderDetails.service_center_email}</td>
                            </tr>                
                            <tr>
                                <td>Phone</td>
                                <td className="fw-semibold">{orderDetails.service_center_phone}</td>
                            </tr>              
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Tab>
            <Tab eventKey="invoice" title="Invoice details">
                <Row xs={12}>
                    <Col xs={12} sm={6} lg={4}>
                        <Table bordered responsive>
                            <thead>
                            <tr>
                                <th colSpan="2">Order Invoices</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Invoice 1</td>
                                <td className="fw-semibold">{orderDetails.invoice_1_no}</td>
                            </tr>
                            <tr>
                                <td>Invoice 2</td>
                                <td className="fw-semibold">{orderDetails.invoice_2_no}</td>
                            </tr>
                            <tr>
                                <td>Invoice Date</td>
                                <td className="fw-semibold">{orderDetails.invoice_date?moment(orderDetails.invoice_date).format("DD/MM/YY"):""}</td>
                            </tr>                             
                            </tbody>
                        </Table>
                    </Col>
                    <Col xs={12} sm={6} lg={8}>
                        <Table bordered responsive>
                            <thead>
                            <tr>
                                <th colSpan="3">Products Issued</th>
                            </tr>
                            <tr>
                                <th>Invoice No</th>
                                <th>Product Name</th>
                                <th className="text-end">Quantity</th>
                            </tr>
                            </thead>
                            <tbody>
                            {orderItems.map((row,index)=>
                                <tr key={"OP"+row.txn_no+index}>
                                <td>{row.txn_no}</td>
                                <td>{row.product_name}</td>
                                <td className="text-end">{row.units}</td>
                                </tr>                                                              
                            )}
                            </tbody>
                        </Table>
                    </Col>                    
                </Row>
            </Tab>
            <Tab eventKey="assets" title="Asset details">
                <Row xs={12}>                   
                    <Col xs={12} sm={12} lg={12} className="mb-3">
                    {viewState==="assets"?(
                        <EditAssets data={orderDetails} getOrderDetails={getOrderDetails} close={()=> setViewState("")} />
                    ):(
                        <>
                            <Table bordered responsive>
                                <thead>
                                <tr>
                                    <th colSpan="4">Order Assets</th>
                                </tr>
                                <tr>
                                    <th className='text-nowrap'>Motor SR No.</th>
                                    <th className='text-nowrap'>Controller SR No.</th>
                                    <th className='text-nowrap'>Pump Head SR No.</th>
                                    <th className='text-nowrap'>PV SR No.</th>
                                </tr>
                                </thead>
                                <tbody>                
                                <tr>
                                    <td className='text-break'>{orderDetails.motor_sr_no}</td>
                                    <td className='text-break'>{orderDetails.controller_sr_no}</td>
                                    <td className='text-break'>{orderDetails.pump_head_sr_no}</td>
                                    <td className='text-break'>{
                                        orderDetails.pv_sr_no?.split(";").map((pv, i)=> 
                                            <Fragment key={pv+i}>
                                                <p className="mb-0">{pv}</p>                                            
                                            </Fragment>                                        
                                        )
                                        }
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                            <Button variant="primary" onClick={()=> setViewState("assets")}>
                                <FontAwesomeIcon icon={faPencil} /> Edit
                            </Button>
                        </>
                    )}
                    </Col>
                </Row>
            </Tab> 
            <Tab eventKey="attachments" title="Attachments">                
                {viewState==="attachments"?(
                        <EditAttachments data={orderDetails} getOrderDetails={getOrderDetails} close={()=> setViewState("")} />
                    ):(
                    <Container fluid>
                        <h6 className="fw-bold">Order Attachments</h6>
                        <Row xs={12}>                                                                                
                            <Col xs={12} sm={12} md={6} lg={6} className="p-2 border">
                                <h6 className="fw-semibold">After Installation</h6>
                                <a href={orderDetails.after_foundation_photo_path?orderDetails.after_foundation_photo_path:"#"} target="_blank" rel="noreferrer">"
                                    <img className="img-fluid" src={orderDetails.after_foundation_photo_path} alt="After Foundation Image" />
                                </a>
                                <blockquote>{orderDetails.after_foundation_photo_date}</blockquote>
                                <blockquote>{orderDetails.after_foundation_photo_updated_by}</blockquote>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} className="p-2 border">
                                <h6 className="fw-semibold">Full System</h6>
                                <a href={orderDetails.full_system_photo_path?orderDetails.full_system_photo_path:"#"} target="_blank" rel="noreferrer">"
                                    <img className="img-fluid" src={orderDetails.full_system_photo_path} alt="Full System Image" />
                                </a>
                                <blockquote>{orderDetails.full_system_photo_date}</blockquote>
                                <blockquote>{orderDetails.full_system_photo_updated_by}</blockquote>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} className="p-2 border">
                                <h6 className="fw-semibold">Controller</h6>
                                <a href={orderDetails.controller_photo_path?orderDetails.controller_photo_path:"#"} target="_blank" rel="noreferrer">"
                                    <img className="img-fluid" src={orderDetails.controller_photo_path} alt="Controller Image" />
                                </a>
                                <blockquote>{orderDetails.controller_photo_date}</blockquote>
                                <blockquote>{orderDetails.controller_photo_updated_by}</blockquote>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} className="p-2 border">
                                <h6 className="fw-semibold">Water Output</h6>
                                <a href={orderDetails.water_output_photo_path?orderDetails.water_output_photo_path:"#"} target="_blank" rel="noreferrer">"
                                    <img className="img-fluid" src={orderDetails.water_output_photo_path} alt="Water Output Image" />
                                </a>
                                <blockquote>{orderDetails.water_output_photo_date}</blockquote>
                                <blockquote>{orderDetails.water_output_photo_updated_by}</blockquote>
                            </Col>
                        </Row>
                        <Button className="mt-3" variant="primary" onClick={()=> setViewState("attachments")}>
                            <FontAwesomeIcon icon={faPencil} /> Edit
                        </Button>
                    </Container>
                )}
            </Tab>          
          </Tabs>             
        </div>
      ):(
        <> Loading ...</>
      )
    );
}


function EditProjectOrder(props) {
    const [order, setOrder] = useState(props.data);
    const [projects, setProjects] = useState([]);    

    const getProjects = async() => {        
        try {
            const response = await fetch('/api/projects');
            const json = await response.json();
            setProjects(json.length?json:[]);
        } catch (error) {
            console.error(error);
        }           
    }

    const handleInputChange = (e)=> {
        const name = e.target.name;
        const value = e.target.value;
        setOrder((prevState)=> ({
            ...prevState,
            [name] : value
        }));
    }

    const handleSubmit = (e)=> {
        e.preventDefault();
        const data = {...order};
        console.log("data: ", data);
        if(data.id){
            updateData('/api/order/'+data.id, data)
            .then((response) => {
                console.log(response);
                toast.success(response.message);
                props.getOrderDetails();
            });
        }
    }

    useEffect(()=> {
        getProjects();
    },[props])
    
    return (
        <div className="order-edit-form p-3 bg-white shadow-sm rounded border-0">
            <h5 className="mb-3 fw-bold"> Update Project & Order details </h5>
            <form onSubmit={handleSubmit}>
                <fieldset id="project-from" className="my-0 mx-auto">
                <div className="row">
                    <div className="col-xs-12 col-md-6 mb-3">                    
                        <label htmlFor="project_code">Project Code</label>
                        <select name="project_code" className="form-select shadow-none" value={order.project_code} onChange={handleInputChange}>
                            <option value="">-- Project filter --</option> 
                            {projects.map(p=>
                                <option key={"P"+p.id} value={p.project_code}>{p.project_name}</option> 
                            )}
                        </select>
                    </div>                   
                    <div className="col-xs-12 col-md-6 mb-3">
                        <label htmlFor="primary_no">Order Primary No.</label>
                        <input type="text" name="primary_no" className="form-control shadow-none" maxLength="100" value={order.primary_no} onChange={handleInputChange} required />
                    </div>                          
                    <div className="col-xs-12 col-md-6 mb-3">
                        <label htmlFor="work_order_no">Work Order No.</label>
                        <input type="text" name="work_order_no" className="form-control shadow-none" maxLength="100" value={order.work_order_no} onChange={handleInputChange} />
                    </div>  
                    <div className="col-xs-6 col-md-6 mb-3">
                        <label htmlFor="application_no">Application No.</label>
                        <input type="text" name="application_no" className="form-control shadow-none" maxLength="100" value={order.application_no} onChange={handleInputChange} />
                    </div>
                    <div className="col-xs-6 col-md-6 mb-3">
                        <label htmlFor="order_date">Order Date</label>
                        <input type="date" name="order_date" className="form-control shadow-none" value={moment(order.order_date).format("YYYY-MM-DD")} onChange={handleInputChange} required />
                    </div>
                    <div className="col-xs-6 col-md-6 mb-3"></div> 
                    <div className="col-xs-6 col-md-6 mb-3">
                        <label htmlFor="pump_type">Required Pump Type</label>
                        <select name="pump_type" className="form-select shadow-none" value={order.pump_type} onChange={handleInputChange}>
                            <option value="">-- None --</option>
                            <option value="AC"> AC </option>
                            <option value="DC"> DC </option>
                            <option value="USPC AC"> USPC AC </option>
                            <option value="USPC DC"> USPC DC </option>     
                        </select>
                    </div>
                    <div className="col-xs-6 col-md-6 mb-3">
                        <label htmlFor="pump_sub_type">Required Pump Subtype</label>
                        <select name="pump_sub_type" className="form-select shadow-none" value={order.pump_sub_type} onChange={handleInputChange}>
                            <option value="">-- None --</option>                                  
                            <option value="Surface"> Surface </option>
                            <option value="Submersible"> Submersible </option>
                        </select>
                    </div>
                    <div className="col-xs-6 col-md-6 mb-3">
                        <label htmlFor="pump_capacity">Required Pump Capacity</label>
                        <select name="pump_capacity" className="form-select shadow-none" value={order.pump_capacity} onChange={handleInputChange}>
                            <option value="">-- None --</option>
                            <option value={1}> 1 HP</option>
                            <option value={2}> 2 HP</option>
                            <option value={3}> 3 HP</option>
                            <option value={5}> 5 HP</option>
                            <option value={7.5}> 7.5 HP</option>
                            <option value={10}> 10 HP</option>
                        </select>
                    </div>
                    <div className="col-xs-6 col-md-6 mb-3">                    
                        <label htmlFor="pump_head_mtrs">Head Length</label>
                        <select name="pump_head_mtrs" className="form-select shadow-none" value={order.pump_head_mtrs} onChange={handleInputChange}>
                            <option value="">-- None --</option>
                            <option value={10}> 10 MTS</option>
                            <option value={20}> 20 MTS</option>
                            <option value={30}> 30 MTS</option>
                            <option value={50}> 50 MTS</option>
                            <option value={70}> 70 MTS</option>
                            <option value={100}> 100 MTS</option>
                        </select>
                    </div> 
                </div>                                                                  
                <div className="mt-3">
                    <input type="submit" className="btn btn-primary fw-bold" value="Submit" />
                    <button type="button" className="btn btn-outline-secondary fw-bold ms-3" onClick={props.close}>Cancel</button>
                </div>
                </fieldset>
            </form>
        </div>
    );
}

function EditFarmer(props) {
    const [farmer, setFarmer] = useState(props.data);
    const [states, setStates] = useState([]);
    const [districts, setDistricts] = useState([]);

    const getStates = async() => {        
        try {
            const response = await fetch('/api/states');
            const json = await response.json();
            setStates(json.length?json:[]);
        } catch (error) {
            console.error(error);
        }            
    }

    const getDistricts = async() => {        
        try {
            const response = await fetch('/api/districts');
            const json = await response.json();
            setDistricts(json.length?json:[]);
        } catch (error) {
            console.error(error);
        }            
    }

    const handleInputChange = (e)=> {
        const name = e.target.name;
        const value = e.target.value;
        setFarmer((prevState)=> ({
            ...prevState,
            [name] : value
        }));
    }

    const handleSubmit = (e)=> {
        e.preventDefault();
        const data = {...farmer};    
        if(data.id){
            updateData('/api/farmer/'+data.id, data)
            .then((response) => {
                console.log(response);
                toast.success(response.message);             
                props.getOrderDetails();
            });
        }
    }

    useEffect(()=> {
        getStates();
        getDistricts();
    },[props])
    
    return (
        <div className="order-edit-form p-3 bg-white shadow-sm rounded border-0">
            <h5 className="mb-3 fw-bold"> Update Farmer details </h5>
            <form onSubmit={handleSubmit}>
                <fieldset id="project-from" className="my-0 mx-auto">
                <div className="row">                               
                    <div className="col-xs-12 col-md-6 mb-3">
                        <label htmlFor="bid_saral_no">SARAL No.</label>
                        <input type="text" name="bid_saral_no" className="form-control shadow-none" maxLength="100" value={farmer.bid_saral_no} onChange={handleInputChange} required />
                    </div>                          
                    <div className="col-xs-12 col-md-6 mb-3">
                        <label htmlFor="farmer_name">Farmer Name</label>
                        <input type="text" name="farmer_name" className="form-control shadow-none" maxLength="100" value={farmer.farmer_name} onChange={handleInputChange} required />
                    </div>  
                    <div className="col-xs-6 col-md-6 mb-3">
                        <label htmlFor="village">Village</label>
                        <input type="text" name="village" className="form-control shadow-none" maxLength="100" value={farmer.village} onChange={handleInputChange} />
                    </div>
                    <div className="col-xs-6 col-md-6 mb-3">
                        <label htmlFor="taluka">Taluka</label>
                        <input type="text" name="taluka" className="form-control shadow-none" value={farmer.taluka} onChange={handleInputChange} />
                    </div>                    
                    <div className="col-xs-6 col-md-6 mb-3">
                        <label htmlFor="state">State</label>
                        <select name="state" className="form-select shadow-none" value={farmer.state} onChange={handleInputChange} required >
                            <option value="">-- None --</option>                            
                            {states.map(s=>
                                <option key={"S"+s.state_code} value={s.state_name}>{s.state_name}</option> 
                            )}
                        </select>
                    </div>
                    <div className="col-xs-6 col-md-6 mb-3">
                        <label htmlFor="district">District</label>
                        <select name="district" className="form-select shadow-none" value={farmer.district} onChange={handleInputChange} required >
                            <option value="">-- None --</option>                            
                            {districts.map(d=>
                                <option key={"D"+d.district_code} value={d.district_name}>{d.district_name}</option> 
                            )}
                        </select>
                    </div>
                    <div className="col-xs-6 col-md-6 mb-3">
                        <label htmlFor="mobile_number">Mobile Number</label>
                        <input type="text" name="mobile_number" className="form-control shadow-none" maxLength="100" value={farmer.mobile_number} onChange={handleInputChange} />
                    </div>
                    <div className="col-xs-6 col-md-6 mb-3">
                        <label htmlFor="latitude">Latitude</label>
                        <input type="text" name="latitude" className="form-control shadow-none" value={farmer.latitude} onChange={handleInputChange} />
                    </div>
                    <div className="col-xs-6 col-md-6 mb-3">
                        <label htmlFor="longitude">Longitude</label>
                        <input type="text" name="longitude" className="form-control shadow-none" maxLength="100" value={farmer.longitude} onChange={handleInputChange} />
                    </div>
                    <div className="col-xs-6 col-md-6 mb-3">
                        <label htmlFor="empanelled_agency">Empanelled Agency</label>
                        <input type="text" name="empanelled_agency" className="form-control shadow-none" value={farmer.empanelled_agency} onChange={handleInputChange} />
                    </div>    
                </div>                                                                  
                <div className="mt-3">
                    <input type="submit" className="btn btn-primary fw-bold" value="Submit" />
                    <button type="button" className="btn btn-outline-secondary fw-bold ms-3" onClick={props.close}>Cancel</button>
                </div>
                </fieldset>
            </form>
        </div>
    );
}

function EditAssets(props) {
    const [assets, setAssets] = useState(props.data);   
    
    const handleInputChange = (e)=> {
        const name = e.target.name;
        const value = e.target.value;
        setAssets((prevState)=> ({
            ...prevState,
            [name] : value
        }));
    }

    const handleSubmit = (e)=> {
        e.preventDefault();
        const data = {...assets};    
        if(data.id){
            updateData('/api/asset/'+data.id, data)
            .then((response) => {
                console.log(response);
                toast.success(response.message);
                props.getOrderDetails();            
            });
        }
    }
    
    return (
        <div className="order-edit-form p-3 bg-white shadow-sm rounded border-0">
            <h5 className="mb-3 fw-bold">Update Assets details </h5>
            <form onSubmit={handleSubmit}>
                <fieldset id="project-from" className="my-0 mx-auto">
                <div className="row">                               
                    <div className="col-xs-12 col-md-12 mb-3">
                        <label htmlFor="motor_sr_no">Motor Serial No.</label>
                        <input type="text" name="motor_sr_no" className="form-control shadow-none" maxLength="100" value={assets.motor_sr_no} onChange={handleInputChange} />
                    </div>                          
                    <div className="col-xs-12 col-md-12 mb-3">
                        <label htmlFor="controller_sr_no">Controller Serial No.</label>
                        <input type="text" name="controller_sr_no" className="form-control shadow-none" maxLength="100" value={assets.controller_sr_no} onChange={handleInputChange} />
                    </div>
                    <div className="col-xs-12 col-md-12 mb-3">
                        <label htmlFor="pump_head_sr_no">PV Serial No.</label>
                        <input type="text" name="pump_head_sr_no" className="form-control shadow-none" maxLength="100" value={assets.pump_head_sr_no} onChange={handleInputChange} />
                    </div>
                    <div className="col-xs-12 col-md-12 mb-3">
                        <label htmlFor="pv_sr_no">Pump Head Serial No.</label>
                        <textarea name="pv_sr_no" className="form-control shadow-none" rows={4} maxLength="255" value={assets.pv_sr_no} onChange={handleInputChange} />
                    </div>
                </div>                                                                    
                <div className="mt-3">
                    <input type="submit" className="btn btn-primary fw-bold" value="Submit" />
                    <button type="button" className="btn btn-outline-secondary fw-bold ms-3" onClick={props.close}>Cancel</button>
                </div>
                </fieldset>
            </form>
        </div>
    );
}

function EditAttachments(props) {
    const [attachments, setAttachments] = useState(props.data);   
    
    const handleInputChange = (e)=> {
        const name = e.target.name;
        const value = e.target.value;
        setAttachments((prevState)=> ({
            ...prevState,
            [name] : value
        }));
    }

    const handleFileSubmit = (e)=> {
        e.preventDefault();
        if (attachments.id) {
          var formData = new FormData();
          var file = document.getElementById("update-order-file").files[0];
          if(file){
            formData.append('files', file);
            formData.append('activity_name', attachments.activity_name);
            var ORfileds = document.getElementById("order-attachment-update-fields");
            ORfileds.disabled = true;
            postFile(`/api/update-order-files/${attachments.id}`, formData)
            .then((response) => {
              toast.success(response.message);
              ORfileds.disabled = false;
              document.getElementById("order-attachment-update-form").reset();             
              props.getOrderDetails();
            }).catch(e=> toast.error(e));
          }else{
            toast.warning("Select the file first!");
          }     
        }
    }
    
    return (
        <div className="order-edit-form p-3 bg-white shadow-sm rounded border-0 w-50">
            <h5 className="mb-3 fw-bold"> Update Attchments </h5>
            <form id="order-attachment-update-form" onSubmit={handleFileSubmit} autoComplete="off">
                <fieldset id="order-attachment-update-fields" className="my-0 mx-auto">
                    <div className="row">
                        <div className="col-xs-12 col-md-12 mb-3">
                            <label htmlFor="primary_no">Primary No. / Farmer Name</label>
                            <input type="text" name="primary_no" className="form-control shadow-none bg-light" value={attachments.primary_no} list="farmer-list" readOnly />
                        </div>                                            
                        <div className="col-xs-12 col-md-12 mb-3">
                            <label htmlFor="activity_name">Activity</label>
                            <select name="activity_name" className="form-select shadow-none" value={attachments.activity_name} onChange={handleInputChange} required>
                                <option value=""> -- Select Activity -- </option>
                                {
                                    OrderActivities.map((o,i)=>
                                    <option key={o+i} value={o}>{o}</option> 
                                    )
                                }
                            </select>
                        </div>
                        <div className="col-xs-12 col-md-12 mb-3">
                          {attachments.activity_name && 
                            <>
                              <p className="text-secondary fw-light mb-1">Upload your attachment here (.png / .jpg / .jpeg file)</p>
                              <div className="mb-2">
                                <label>{attachments.activity_name} file</label>
                                <input type="file" id="update-order-file" className="form-control" required />
                              </div>
                            </>
                          }
                        </div>    
                        <div className="my-2 col-xs-12 col-md-6 pt-3">
                            <input type="submit" className="btn btn-primary fw-bold" value="Submit" />
                            <button type="button" className="btn btn-outline-secondary fw-bold ms-3" onClick={props.close}>Cancel</button>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    );
}

  export { OrderDetails };