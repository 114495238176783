import { Component, useMemo, useState } from 'react';
import { postFile, updateData, customTableStyles } from '../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faPlus, faClose } from '@fortawesome/free-solid-svg-icons';
import {  faFileExcel, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import DataTable from 'react-data-table-component';
import ImportFile from '../downloads/status_update.xlsx';
import { toast } from 'react-toastify';
const XLSX = require("xlsx");
const moment = require('moment');


class OrderStatusUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      orderStatuses: [],
      selectedOrder: {},
      pageIndex: 1,
      isLoading: true,
      show: false,
      show_content: "",
    };
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.view = this.view.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  async getOrderStatus() {
    try {
      const response = await fetch('/api/orders-status');
      const json = await response.json();
      console.log(json);
      this.setState({ data: json.length ? json : [] });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async getOrderActivities() {
    try{
      const response = await fetch("/api/order-activities");
      const json = await response.json();
      this.setState({ orderStatuses: json.length ? json : [] });
    }catch(e){
      toast.error(e)
    }
  }

  handlePageChange(newPageIndex) {
    this.setState({
      pageIndex: newPageIndex
    });
  }

  add() {
    this.setState({
      id: "",
      show: true,
      show_content: "upload"
    });
  }

  edit(Id) {
    const orders = this.state.data;
    const selectedOrder = orders.filter(e => e.id === Id)[0];
    this.setState(selectedOrder);
    this.setState({ show: true, show_content: "form", filename: "" });
  }

  view(row, event) {
    const selectedOrder = this.state.data.filter(e => e.id === row.id)[0];
    this.setState({ show: true, show_content: "", selectedOrder: selectedOrder });
  }

  componentDidMount() {
    this.getOrderStatus();
    this.getOrderActivities();
  }

  handleInputChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.id) {
      const data = {
        "primary_no": this.state.primary_no,
        "application_status": this.state.application_status
      };
      updateData(`/api/order-status/${this.state.id}`, data)
        .then((response) => {
          toast.success(response.message);
          this.setState({ show: false });          
          this.getOrderStatus();
        });
    } else {
      var formData = new FormData();
      var file = document.getElementById("order-sheet").files[0];
      if (file) {
        var ORfileds = document.getElementById("order-fields");
        ORfileds.disabled = true;
        formData.append('File', file);
        postFile('/api/order-status-upload', formData)
          .then((response) => {
            toast.success(response.message);
            ORfileds.disabled = false;
            document.getElementById("order-form").reset();
            this.setState({ show: false, show_content: "" });            
            this.getOrderStatus();
          });
      } else {
        toast.warning("Select the file first");
      }
    }
  }

  render() {
    if (!this.state.isLoading) {      
      return (
        <>
          {!this.state.show ? (
            <>
              <div className="d-flex">
                <button className="btn btn-sm btn-primary fw-bold mb-1 ms-2" onClick={this.add}>
                  <FontAwesomeIcon icon={faPlus} /> Upload Order Status
                </button>
                <a href={ImportFile} className="fw-bold mt-1 ms-2">
                  <FontAwesomeIcon icon={faFileExcel} /> Download Template
                </a>
              </div>
              <OrderList data={this.state.data} edit={this.edit} view={this.view} pageIndex={this.state.pageIndex} handlePageChange={this.handlePageChange} />
            </>
          ) : (
            <>
              {this.state.show_content === "upload" ? (
                <>
                  <div className="card order-form p-3 bg-white shadow-sm rounded w-50 border-0">
                    <h4 className="mb-3 fw-bold"> Upload Order Status </h4>
                    <form id="order-form" onSubmit={this.handleSubmit}>
                      <fieldset id="order-fields" className="my-0 mx-auto">
                        <div className="mb-3">
                          <p className="text-secondary fw-light">Upload your excel sheet here. (.xlsx / .csv file)</p>
                          <label htmlFor="title">Order status update file</label>
                          <input type="file" id="order-sheet" className="form-control" onChange={this.handleFileChange} required />
                        </div>
                        <div className="my-2">
                          <input type="submit" className="btn btn-primary fw-bold" value="Submit" />
                          <button type="button" className="btn btn-outline-secondary fw-bold ms-3" onClick={() => this.setState({ show: false })}>Cancel</button>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </>
              ) : (
                <>
                  <div className="h-100 w-50">
                    <div className="vendor-form p-4 bg-white shadow-sm rounded border-0">
                      <h4 className="mb-3 fw-bold"> Status Update </h4>
                      <form onSubmit={this.handleSubmit}>
                        <fieldset id="order-update-from" className="my-0 mx-auto">
                          <div className="row">
                            <div className="col-xs-12 col-md-12 mb-3">
                              <label htmlFor="primary_no">Primary No.</label>
                              <input type="text" name="primary_no" className="form-control shadow-none bg-light" value={this.state.primary_no} readOnly />
                            </div>
                            <div className="col-xs-12 col-md-12 mb-3">
                              <label htmlFor="application_status">Status</label>
                              <select name="application_status" className="form-select shadow-none" value={this.state.application_status} onChange={this.handleInputChange} required>
                                {
                                  this.state.orderStatuses.map((o,i)=>
                                   <option key={o.status+i} value={o.activity}>{o.activity}</option> 
                                  )
                                }
                              </select>
                            </div>
                          </div>
                          <div>
                            <input type="submit" className="btn btn-primary fw-bold" value="Submit" />
                            <button type="button" className="btn btn-outline-secondary fw-bold ms-3" onClick={() => this.setState({ show: false })}>Cancel</button>
                          </div>
                        </fieldset>
                      </form>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      );
    }
    else {
      return (
        <>Loading...</>
      );
    }
  }
}

const OrderList = (props) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = props.data.filter(item => {
    const primaryNoMatches = item.primary_no && item.primary_no.toLowerCase().includes(filterText.toLowerCase());
    const farmerMatches = item.farmer_name && item.farmer_name.toLowerCase().includes(filterText.toLowerCase());
    const orderStatusMatches = item.application_status && item.application_status.toLowerCase().includes(filterText.toLowerCase());

    return primaryNoMatches || farmerMatches || orderStatusMatches
  });

  const actionComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    const XLSXDownload = () => {
      var worksheet = XLSX.utils.json_to_sheet(filteredItems);
      const workbook = XLSX.utils.book_new();
      const filename = `OMS_Order_Accepted_${moment().format('YYYYMMDD')}`;
      XLSX.utils.book_append_sheet(workbook, worksheet, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    }

    return (
      <div className="d-flex justify-content-end align-items-center">
        <div className="input-group input-group-sm mx-2">
          <input
            type="text"
            className="form-control shadow-none"
            placeholder="Search by project's code, name ..."
            aria-label="Search area"
            value={filterText}
            onChange={e => setFilterText(e.target.value)}
          />
          <button className="btn btn-light border" type="button" onClick={handleClear}>
            <FontAwesomeIcon icon={faClose} />
          </button>
        </div>
        <div>
          <button className="btn btn-sm btn-outline-success mx-2 d-flex align-items-center" type="button" onClick={XLSXDownload}>
            <FontAwesomeIcon className="pe-2" icon={faFileExcel} /> Export
          </button>
        </div>
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: 'Primary No.',
      selector: row => row.primary_no,
      sortable: true,
    },
    {
      name: 'Consumer Name',
      selector: row => row.farmer_name,
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => row.application_status,
      sortable: true,
    },
    {
      name: 'Action',
      selector: row => row.edit
    }
  ];

  const data = filteredItems.map((item, index) => {
    return {
      id: item.id,
      primary_no: item.primary_no,
      farmer_name: item.farmer_name,
      application_status: item.application_status,
      edit: <FontAwesomeIcon icon={faPenToSquare} color="blue" className="action-icons" onClick={() => props.edit(item.id)} />
    }
  });

  const THeader = () => {
    return (
      <>
        <h4 className="mb-0">Status Update</h4>
      </>
    );
  }

  return (
    <>
      <div className="mb-2 block p-2">
        <div className="shadow">
          <DataTable
            title={<THeader />}
            columns={columns}
            data={data}
            actions={actionComponentMemo}
            customStyles={customTableStyles}
            pagination
            paginationDefaultPage={props.pageIndex}
            onChangePage={props.handlePageChange}
            fixedHeader
            fixedHeaderScrollHeight="450px" //remove this if you dont need table scroll            
            selectableRowsHighlight            
            className=" border z-10"
          />
        </div>
      </div>
    </>
  );
}


export { OrderStatusUpdate };
