import { Component, useMemo, useState } from 'react';
import { postFile, customTableStyles } from '../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faPlus, faFile, faClose } from '@fortawesome/free-solid-svg-icons';
import {  faFileExcel, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
const XLSX = require("xlsx");
const moment = require('moment');
const OrderActivities = [
  "After Foundation", 
  "Full System", 
  "Controller", 
  "Water Output",
];

class OrderAttachments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      selectedOrder: {},
      pageIndex: 1,
      isLoading: true,
      show: false,
      show_content: "",
    };
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.view = this.view.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFileSubmit = this.handleFileSubmit.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  async getOrderAttachments() {
    try {
      const response = await fetch('/api/all-order-files');
      const json = await response.json();
      console.log(json);
      this.setState({ data: json.length ? json : [] });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handlePageChange(newPageIndex) {
    this.setState({
      pageIndex: newPageIndex
    });
  }

  add() {
    this.setState({
      id: "",
      order_id: "",
      activity_name: "",
      primary_no: "",
      show: true,
      show_content: "upload"
    });
  }

  edit(Id) {
    const orders = this.state.data;
    const selectedOrder = orders.filter(e => e.id === Id)[0];
    this.setState(selectedOrder);
    this.setState({ show: true, show_content: "form", order_id:"", activity_name: "", filename: "" });
  }

  view(row, event) {
    const selectedOrder = this.state.data.filter(e => e.id === row.id)[0];
    this.setState({ show: true, show_content: "", selectedOrder: selectedOrder });
  }

  componentDidMount() {
    this.getOrderAttachments();
  }

  handleInputChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });
    if(name==="primary_no"){
      const selectedFarmer = this.state.data.filter(e=> e.primary_no===value)[0];
      if(selectedFarmer){
        this.setState({"order_id": selectedFarmer.id});
      }else{
        toast.warning("Order Primary No. not matched with any order! Select a valid application.");
      }
    }
  }

  handleFileSubmit(event) {
    event.preventDefault();
    if (this.state.id) {
      var formData = new FormData();
      var file = document.getElementById("update-order-file").files[0];
      if(file){
        formData.append('files', file);
        formData.append('activity_name', this.state.activity_name);
        var ORfileds = document.getElementById("order-attachment-update-fields");
        ORfileds.disabled = true;
        postFile(`/api/update-order-files/${this.state.id}`, formData)
        .then((response) => {
          toast.success(response.message);
          ORfileds.disabled = false;
          document.getElementById("order-attachment-update-form").reset();
          this.setState({ show: false, show_content: "" });            
          this.getOrderAttachments();
        }).catch(e=> toast.error(e));
      }else{
        toast.warning("Select the file first!");
      }     
    } else {
      var formData = new FormData();
      if(this.state.order_id){
        var foundationFile = document.getElementById("after-foundation-file").files[0];
        var fullSystemFile = document.getElementById("full-system-file").files[0];
        var controllerFile = document.getElementById("controller-file").files[0];
        var waterOutputFile = document.getElementById("water-output-file").files[0];
        
        if (foundationFile || fullSystemFile || controllerFile || waterOutputFile) {
          var ORfileds = document.getElementById("order-attachment-fields");
          ORfileds.disabled = true;
          
          if(foundationFile) formData.append('After Foundation', foundationFile);
          if(fullSystemFile) formData.append('Full System', fullSystemFile);
          if(controllerFile) formData.append('Controller', controllerFile);
          if(waterOutputFile) formData.append('Water Output', waterOutputFile);

          postFile(`/api/order-files/${this.state.order_id}`, formData)
          .then((response) => {
            toast.success(response.message);
            ORfileds.disabled = false;
            document.getElementById("order-attachment-form").reset();
            this.setState({ show: false, show_content: "" });            
            this.getOrderAttachments();
          }).catch(e=> toast.error(e));
        } else {
          toast.warning("Select the file first!");
        }
      }else{
        toast.warning("Select a Valid Application First!.")
      }      
    }
  }

  render() {
    if (!this.state.isLoading) {
      const data = this.state.data;
      return (
        <>
          <datalist id="farmer-list">
            {data.map(o=>
              <option key={o.primary_no} value={o.primary_no}>{o.farmer_name}</option>
            )}
          </datalist>
          {!this.state.show ? (
            <>
              <div className="d-flex">
                <button className="btn btn-sm btn-primary fw-bold mb-1 ms-2" onClick={this.add}>
                  <FontAwesomeIcon icon={faPlus} /> Upload Order Attachment
                </button>               
              </div>
              <AttachmentList data={data} edit={this.edit} view={this.view} pageIndex={this.state.pageIndex} handlePageChange={this.handlePageChange} />
            </>
          ) : (
            <>
              {this.state.show_content === "upload" ? (
                <>
                  <div className="card order-form p-3 bg-white shadow-sm rounded w-50 border-0">
                    <h4 className="mb-3 fw-bold"> Upload Order Attachment </h4>
                    <form id="order-attachment-form" onSubmit={this.handleFileSubmit} autoComplete="off">
                      <fieldset id="order-attachment-fields" className="my-0 mx-auto">
                        <div className="row">
                          <div className="col-xs-12 col-md-12 mb-3">
                            <label htmlFor="primary_no">Primary No. / Farmer Name</label>
                            <input type="text" name="primary_no" className="form-control shadow-none" value={this.state.primary_no} onChange={this.handleInputChange} list="farmer-list" required />
                          </div>
                        </div>
                        <p className="text-secondary fw-light">All attachments should be in (.png / .jpg / .jpeg file)</p>
                        <div className="mb-2">
                          <label>After Foundation file</label>
                          <input type="file" id="after-foundation-file" className="form-control" onChange={this.handleFileChange} />
                        </div>
                        <div className="my-2">
                          <label>Full System file</label>
                          <input type="file" id="full-system-file" className="form-control" onChange={this.handleFileChange} />
                        </div>
                        <div className="my-2">
                          <label>Controller file</label>
                          <input type="file" id="controller-file" className="form-control" onChange={this.handleFileChange} />
                        </div>
                        <div className="my-2">
                          <label>Water Output file</label>
                          <input type="file" id="water-output-file" className="form-control" onChange={this.handleFileChange} />
                        </div>
                        <div className="my-2 pt-3">
                          <input type="submit" className="btn btn-primary fw-bold" value="Submit" />
                          <button type="button" className="btn btn-outline-secondary fw-bold ms-3" onClick={() => this.setState({ show: false })}>Cancel</button>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </>
              ) : (
                <>
                  <div className="h-100 w-50">
                    <div className="vendor-form p-4 bg-white shadow-sm rounded border-0">
                      <h4 className="mb-3 fw-bold"> Attachment Update </h4>
                      <form id="order-attachment-update-form" onSubmit={this.handleFileSubmit} autoComplete="off">
                        <fieldset id="order-attachment-update-fields" className="my-0 mx-auto">
                          <div className="row">
                            <div className="col-xs-12 col-md-12 mb-3">
                              <label htmlFor="primary_no">Order Primary No.</label>
                              <input type="text" name="primary_no" className="form-control shadow-none bg-light" value={this.state.primary_no} readOnly />
                            </div>
                            <div className="col-xs-12 col-md-12 mb-3">
                              <label htmlFor="activity_name">Activity</label>
                              <select name="activity_name" className="form-select shadow-none" value={this.state.activity_name} onChange={this.handleInputChange} required>
                                <option value=""> -- Select Activity -- </option>
                                {
                                  OrderActivities.map((o,i)=>
                                   <option key={o+i} value={o}>{o}</option> 
                                  )
                                }
                              </select>
                            </div>
                          </div>
                          {this.state.activity_name && 
                            <>
                              <p className="text-secondary fw-light mb-1">Upload your attachment here (.png / .jpg / .jpeg file)</p>
                              <div className="mb-2">
                                <label>{this.state.activity_name} file</label>
                                <input type="file" id="update-order-file" className="form-control" onChange={this.handleFileChange} required />
                              </div>
                            </>
                          }                          
                          <div className="my-2 pt-2">
                            <input type="submit" className="btn btn-primary fw-bold" value="Submit" />
                            <button type="button" className="btn btn-outline-secondary fw-bold ms-3" onClick={() => this.setState({ show: false })}>Cancel</button>
                          </div>
                        </fieldset>
                      </form>
                    </div>
                  </div>
                </>
              )}
            </>
          )}          
        </>
      );
    }
    else {
      return (
        <>Loading...</>
      );
    }
  }
}

const AttachmentList = (props) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = props.data.filter(item => {
    const primaryNoMatches = item.primary_no && item.primary_no.toLowerCase().includes(filterText.toLowerCase());
    const farmerMatches = item.farmer_name && item.farmer_name.toLowerCase().includes(filterText.toLowerCase());

    return primaryNoMatches || farmerMatches;
  });

  const actionComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    const XLSXDownload = () => {
      var worksheet = XLSX.utils.json_to_sheet(filteredItems);
      const workbook = XLSX.utils.book_new();
      const filename = `OMS_Order_Attachments_${moment().format('YYYYMMDD')}`;
      XLSX.utils.book_append_sheet(workbook, worksheet, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    }

    return (
      <div className="d-flex justify-content-end align-items-center">
        <div className="input-group input-group-sm mx-2">
          <input
            type="text"
            className="form-control shadow-none"
            placeholder="Search by application_no, farmer's name ..."
            aria-label="Search area"
            value={filterText}
            onChange={e => setFilterText(e.target.value)}
          />
          <button className="btn btn-light border" type="button" onClick={handleClear}>
            <FontAwesomeIcon icon={faClose} />
          </button>
        </div>
        <div>
          <button className="btn btn-sm btn-outline-success mx-2 d-flex align-items-center" type="button" onClick={XLSXDownload}>
            <FontAwesomeIcon className="pe-2" icon={faFileExcel} /> Export
          </button>
        </div>
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: 'Primary No.',
      selector: row => row.primary_no,
      sortable: true,
    },
    {
      name: 'Consumer Name',
      selector: row => row.farmer_name,
      sortable: true,
    },
    {
      name: 'After Foundation',
      selector: row => row.after_foundation_photo_path,    
    },
    {
      name: 'Full System',
      selector: row => row.full_system_photo_path,    
    },
    {
      name: 'Controller',
      selector: row => row.controller_photo_path,    
    },
    {
      name: 'Water Output',
      selector: row => row.water_output_photo_path,
    },
    {
      name: 'Action',
      selector: row => row.edit
    }
  ];

  const data = filteredItems.map((item, index) => {
    return {
      id: item.id,
      primary_no: item.primary_no,
      farmer_name: item.farmer_name,      
      after_foundation_photo_path: item.after_foundation_photo_path?<a target="_blank" href={item.after_foundation_photo_path} className="fw-bold"><FontAwesomeIcon icon={faFile} color="blue"/> Open File</a>:"",
      full_system_photo_path: item.full_system_photo_path?<a target="_blank" href={item.full_system_photo_path} className="fw-bold"><FontAwesomeIcon icon={faFile} color="blue"/> Open File</a>:"",
      controller_photo_path: item.controller_photo_path?<a target="_blank" href={item.controller_photo_path} className="fw-bold"><FontAwesomeIcon icon={faFile} color="blue"/> Open File</a>:"",
      water_output_photo_path: item.water_output_photo_path?<a target="_blank" href={item.water_output_photo_path} className="fw-bold"><FontAwesomeIcon icon={faFile} color="blue"/> Open File</a>:"",
      edit: <FontAwesomeIcon icon={faPenToSquare} color="blue" className="action-icons" onClick={() => props.edit(item.id)} />
    }
  });

  const THeader = () => {
    return (
      <>
        <h4 className="mb-0">Order Attachments</h4>
      </>
    );
  }

  return (
    <>
      <div className="mb-2 block p-2">
        <div className="shadow">
          <DataTable
            title={<THeader />}
            columns={columns}
            data={data}
            actions={actionComponentMemo}
            customStyles={customTableStyles}
            pagination
            paginationDefaultPage={props.pageIndex}
            onChangePage={props.handlePageChange}
            fixedHeader
            fixedHeaderScrollHeight="450px" //remove this if you dont need table scroll            
            selectableRowsHighlight            
            className=" border z-10"
          />
        </div>
      </div>
    </>
  );
}


export { OrderAttachments };
