import { useCallback, useEffect, useState, useMemo } from 'react';
import { updateData, deleteItem, customTableStyles } from '../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import DataTable from 'react-data-table-component';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment';
import { Col, ListGroup, Row, Tab, Tabs, Card, Container } from 'react-bootstrap';
import { toast } from 'react-toastify';


function VendorDetails(props) {
  return (
    <Tabs
      defaultActiveKey="products"
      id="justify-tab-example"
      className="mb-3 bg-light rounded-0 shadow-sm"
      justify
    >      
      <Tab eventKey="products" title="Products" tabClassName="rounded-0 fw-semibold">
        <VendorProducts vendor={props.data} close={props.close} />
      </Tab>
      <Tab eventKey="invoices" title="Invoices" tabClassName="rounded-0 fw-semibold">
        <VendorInvoices vendor={props.data} close={props.close} />
      </Tab>      
    </Tabs>    
  );
}

const VendorInfo = (props)=> {
  return(
    <div className="p-3 bg-white shadow-sm rounded w-50">
      <h4 className="mb-3 fw-bold"> {props.data.title} </h4>
      <div className="row">
        <div className="col-md-12 mb-3">
          <p className="mb-1">Vendor Name</p>
          <h6>{props.data.org_name}</h6>
        </div>        
        <div className="col-md-12 mb-3">
          <p className="mb-1">Status</p>
          <h6 className={props.data.is_active?"text-success":"text-danger"}>{props.data.is_active?"Active":"Inactive"}</h6>
        </div>        
        <div>
          <button className="btn btn-secondary fw-bold" onClick={props.close}>
            <FontAwesomeIcon icon={faClose} /> Close
          </button>
        </div>
      </div>
    </div>
  );
}

const VendorProducts = (props)=> {  
  const productObj = { product_id: "", product_name: "" };
  const [products, setProducts] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [vendorItems, setVendorItems] = useState(Array(10).fill(productObj));

  async function getVendorProducts(){
    try {
      const response = await fetch(`/api/vendor-products/${props.vendor.id}`);
      const json = await response.json();          
      // Update specific elements in the vendorItems array
      const updatedProducts = vendorItems.map((item, index) => (index < json.length ? { 'product_id': json[index].product_id, 'product_name': json[index].product_name } : item));      
      setVendorItems(updatedProducts);      
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function getProducts(){
    try {
      const response = await fetch('/api/products');
      const json = await response.json();
      setProducts(json.length?json:[]);
    } catch (error) {
      console.log(error);
    }
  }  

  function selectProduct(e) {
    var index = parseInt(e.target.attributes.dataindex.value);
    const filteredProduct = products.filter(row=> row.product_name === e.target.value);
    if(filteredProduct.length){
      const existingItem = vendorItems.filter(e=> e.product_id === filteredProduct[0].id);
      if(existingItem.length){
        alert("Item already in the list.");
      }else{
        const updatedItems = [...vendorItems];      
        updatedItems[index] = { 'product_id': filteredProduct[0].id, 'product_name': filteredProduct[0].product_name };
        setVendorItems(updatedItems);
      }      
    }else{
      const updatedItems = [...vendorItems];      
      updatedItems[index] = { 'product_id': "", 'product_name': "" };
      setVendorItems(updatedItems);
    }    
  }

  function handleSubmit(e) {
    e.preventDefault();        
    updateData(`/api/vendor-products/${props.vendor.id}`, vendorItems)
      .then((response) => {
        toast.success(response.message);
        getVendorProducts();
        alert(response.message);
    }).catch(e=> alert("Something went wrong! Please try after some time"));    
  } 

  useEffect(()=>{    
    getProducts();
    getVendorProducts();
  },[])

  return (
    <Container fluid>  
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">          
          <h5 className="text-secondary fw-semibold mb-0">Vendor Products - ( {props.vendor.org_name} )</h5>
          <button className="btn btn-sm btn-secondary fw-bold" onClick={props.close}>
            <FontAwesomeIcon icon={faXmark} /> Close
          </button>          
        </Card.Header>
        <Card.Body>
          {!isLoading && 
            <form onSubmit={handleSubmit}>
              <fieldset id="vendor-from" className="my-0 mx-auto">
                <p className="text-muted">Select / Search Product by Name</p>
                <div  className="overflow-y-scroll" style={{height: '55vh'}}>
                  <div className="row g-0">
                    {vendorItems.map((item, index)=> 
                      <div key={index} className="col-xs-12 col-md-12">                  
                        <input
                         type="text"
                         name="product" 
                         className="form-select shadow-none rounded-0" 
                         maxLength="100"
                         dataindex={index}
                         placeholder={`Item ${index+1}`} 
                         value={item.product_name}
                         onChange={selectProduct}
                         list="productOptions"                       
                        />
                      </div>
                    )}                          
                  </div>
                </div>                                    
                <div className="d-flex pt-2">
                  <input type="submit" className="btn btn-primary fw-bold me-2" value="Submit" />
                  <button type="button" className="btn btn-outline-secondary mx-2" onClick={()=> setVendorItems(Array(10).fill(productObj))} >Clear All</button>
                </div>
              </fieldset>
            </form>
          }          
          <datalist id="productOptions">
            {products.map(row=> 
              <option key={row.id} value={row.product_name} />
            )}
          </datalist>
        </Card.Body>
      </Card>       
    </Container>
  );
}

const VendorInvoices = (props)=> {
  const [data, setData] = useState([]);  
  const [selectedInvoice, setSelectedInvoice] = useState({});
  const [show, setShow] = useState(false);
  const [isLoading, setLoading] = useState(true);

  async function getVendorInvoices(){
    try {
      const response = await fetch(`/api/vendor-invoices/${props.vendor.id}`);
      const json = await response.json();
      setData(json.length?json:[]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const view = (row, event)=> {
    const Invoice = data.filter(e=> e.id===row.id)[0];
    setSelectedInvoice(Invoice);
    setShow(true);
  }

  const deleteInvoice = (Ids)=> {
    deleteItem(`/api/vendor-invoices?docRef=[${Ids}]`)
    .then((response) => {
      toast.info(response.message);
      getVendorInvoices();
    });
  }

  useEffect(()=>{    
    getVendorInvoices();
  },[])

  return (
    <Container fluid className="h-100">
      {!show?(
          <VendorInvoiceList data={data} view={view} delete={deleteInvoice} vendor={props.vendor} close={props.close} />
        ):(
          <InvoiceDetails data={selectedInvoice} close={()=> setShow(false)} />
        )
      }
    </Container>
  );
}

const VendorInvoiceList = (props)=> {  
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);  
  
  const filteredItems = props.data.filter(item => {
    const invMatches = item.invoice_no && item.invoice_no.toLowerCase().includes(filterText.toLowerCase());   
    return invMatches;
  });

  const actionComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };    

    return (
      <div className="d-flex justify-content-end align-items-center">
        <div className="input-group input-group-sm mx-2">
          <input
           type="text" 
           className="form-control shadow-none" 
           placeholder="Search by Invoice number ..." 
           aria-label="Search area"          
           value={filterText} 
           onChange={e => setFilterText(e.target.value)}          
          />        
          <button className="btn btn-light border" type="button" onClick={handleClear}>
            <FontAwesomeIcon icon={faClose} />
          </button>          
        </div>        
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const THeader = ()=> {
    return(
      <>
        <h5 className="mb-0">Vendor Invoices - ( {props.vendor.org_name} )</h5>       
      </>
    );
  }

  const columns = [
      {
          name: 'S.NO',
          selector: row => row.no,
          sortable: true,
      },
      {
          name: 'INVOICE NO.',
          selector: row => row.invoice_no,
          sortable: true,
      },
      {
          name: 'INVOICE DATE',
          selector: row => row.invoice_date,
          sortable: true,
      },
      {
          name: 'WAREHOUSE',
          selector: row => row.warehouse,
          sortable: true,
      }      
  ];

  const data = filteredItems.map((item, index)=>{
    return {
      id: item.id,
      no: index+1,
      invoice_no: item.invoice_no,
      invoice_date: moment(item.supply_date).format("DD-MM-YYYY"),
      warehouse: item.warehouse_name
    }
  });

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      confirmAlert({
        title: 'Delete confirmation',
        message: `Are you sure you want to delete:\r ${selectedRows.map(r => r.invoice_no)}?`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              var Ids = selectedRows.map(r => r.id);              
              props.delete(Ids);
              setToggleCleared(!toggleCleared);
            }
          },
          {
            label: 'No',
            onClick: () => console.log("Delete operation cancelled.")
          }
        ]
      });        
        // setData(differenceBy(data, selectedRows, 'title'));      
      
    };

    return (
      <button key="delete" className="btn btn-sm btn-danger" onClick={handleDelete}>
        <FontAwesomeIcon className="me-2" icon={faTrashCan} />
         Delete
      </button>
    );
  }, [data, selectedRows, toggleCleared]);    

  return(
    <>      
      <div className="mb-2 block">
        <div className="shadow">
          <DataTable
            title={<THeader />}                        
            columns={columns}
            data={data}
            actions={actionComponentMemo}
            customStyles={customTableStyles}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="450px" //remove this if you dont need table scroll                                
            selectableRows
            selectableRowsHighlight
            selectableRowsRadio="checkbox"              
            pointerOnHover //remove this if you dont need pointer while row hover
            onRowClicked={props.view}
            className=" border z-10"                    
            contextActions={contextActions}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared}         
          />
        </div>
        <button className="btn btn-secondary fw-bold mt-2" onClick={props.close}>
          <FontAwesomeIcon icon={faClose} /> Close
        </button>  
      </div>       
    </>
  );
}

const InvoiceDetails = (props) => {
  const [productItems, setProductItems] = useState([]);
  const [invoiceFiles, setInvoiceFiles] = useState([]);

  async function getInvoiceItems(){
    try {
      const response = await fetch(`/api/vendor-invoice-items/${props.data.id}`);
      const json = await response.json();
      setProductItems(json.length?json:[]);
    } catch (error) {
      console.log(error);
    }
  }

  async function getInvoiceFiles(){
    try {
      const response = await fetch(`/api/vendor-invoice-files/${props.data.id}`);
      const json = await response.json();
      setInvoiceFiles(json.length?json:[]);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(()=>{
    getInvoiceItems();
    getInvoiceFiles();
  },[])

  return (
    <Row xs={12} sm={12} className="g-0">
      <Col xs={12} sm={8} className="pe-2">
        <Card>
          <Card.Header className="d-flex justify-content-between fw-semibold text-secondary fs-5">
            Invoice Details
            <button type="button" className="btn btn-sm btn-secondary" onClick={props.close}>
              <FontAwesomeIcon icon={faXmark} /> Close
            </button>
          </Card.Header>
          <Card.Body>
            <Row xs={12} sm={12} className="mb-2">
              <Col xs={12} sm={6} md={4}>
                <Card.Text>
                  <h6 className="text-secondary">Invoice No.</h6>
                  <h6 className="text-muted fw-bold px-1">{props.data.invoice_no}</h6>
                </Card.Text>
              </Col>
              <Col xs={12} sm={6} md={4}>
                <Card.Text>
                  <h6 className="text-secondary">Invoice Date</h6>
                  <h6 className="text-muted fw-bold px-1">{moment(props.data.supply_date).format("DD-MM-YYYY")}</h6>
                </Card.Text>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <Card.Text>
                  <h6 className="text-secondary">Warehouse</h6>
                  <h6 className="text-muted fw-bold px-1">{props.data.warehouse_name}</h6>
                </Card.Text>
              </Col>
            </Row>     
            <Row xs={12} sm={12} className="mb-2">
              <Col xs={12} sm={4}>
                <Card.Text>
                  <h6 className="text-secondary">TR No.</h6>
                  <h6 className="text-muted fw-bold px-1">{props.data.tr_no}</h6>
                </Card.Text>
              </Col>
              <Col xs={12} sm={4}>
                <Card.Text>
                  <h6 className="text-secondary">TR Date</h6>
                  <h6 className="text-muted fw-bold px-1">{moment(props.data.tr_date).format("DD-MM-YYYY")}</h6>
                </Card.Text>
              </Col>             
            </Row>                        
            <hr />
            <Card.Title className="text-muted text-decoration-underline">Products </Card.Title>                       
            <ListGroup className="list-group-flush overflow-y-scroll" style={{height: '271px'}}>
              <ListGroup.Item className="bg-secondary text-light fw-semibold">
                <Row xs={12} sm={12}>
                  <Col xs={6} sm={6}>Product Name</Col>
                  <Col xs={3} sm={3}>Units</Col>
                  <Col xs={3} sm={3}>UOM</Col>
                </Row>
              </ListGroup.Item>
              {
                productItems.map((item)=>
                  <ListGroup.Item key={item.id}>
                    <Row xs={12} sm={12}>
                      <Col xs={6} sm={6}>
                        {item.product_name}
                      </Col>
                      <Col xs={3} sm={3}>
                        {item.units}
                      </Col>
                      <Col xs={3} sm={3}>
                        {item.uom}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                )
              }             
            </ListGroup>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12} sm={4}>
        <Card>          
          <Card.Body className="overflow-y-scroll" style={{height: '80vh'}}>
            <h5 className="text-secondary text-decoration-underline pb-2"> Attachments </h5>
            {invoiceFiles.map((file, index)=>
              <embed src={file.file_path} width="250px" height="auto" />
            )}            
          </Card.Body>
        </Card>               
      </Col>
    </Row>
  );
}

export { VendorDetails };